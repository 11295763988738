import axios from 'axios'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import {API_URL} from '../../../../_metronic/helpers/components/constant'

export const AGREEMENTS = `${API_URL}/items/aggrement`
export const FILES = `${API_URL}/files`
const headers = {
  'Authorization': `Bearer ${directUSAPIToken()}`
}
// Server should return AuthModel
export function getLists(tableName, param) {
  return axios.get(`${API_URL}/items/${tableName}${param}`, {
      params: {},
      headers
  })
}

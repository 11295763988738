
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { directUSAPICall } from "../../utils"
import CircularProgress from '@material-ui/core/CircularProgress';
import { PRODUCTCATEGORIES } from "../../constant";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '80%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  dialogRoot: {
    width: '80% !important',
    height: '600px',
    //   display: 'flex',
    //   flexDirection: 'column',
  },
  menu: {
    width: 200,
  },
}));

export default function AlertDialog({
  showDialog,
  selectedItem,
  handleCancel,
  collection,
  userPermission
}) {
  const [description, setDescription] = useState('')
  const [datas, setDatas] = React.useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    months: null,
    equipment: null
  })

  const classes = useStyles()
  function handleClose() {
    handleCancel(false);
  }

  useEffect(() => {
    // setAssetData(selectedItem.data)
    setEditData({})
    getdata()
  }, [])

  const getdata = async (id) => {
    setLoading(true)
    let options = {
      limit: -1,
      sort: '-id',
      fields: ['id,equipment,months'],
      filter: {
        "status": {
          _neq: 'archived'
        }
      },
    }
    const result = await directUSAPICall().items('lifecycle_settings').readMany(options);
    if (result.data && result.data.length > 0) {
      setDatas(result.data);
      setLoading(false)
    } else {
      setDatas([])
      setLoading(false)
    }

  }

  const submit = async () => {
    if (values.months) {
      let result = ""
      let param = {
        equipment: values.equipment,
        months: values.months
      }
      setLoading(true)
      if (values.id) {
        result = await directUSAPICall().items('lifecycle_settings').updateOne(values.id, param);
      } else {
        result = await directUSAPICall().items('lifecycle_settings').createOne(param);
      }
      if (result.id) {
        setValues(
          {
            equipment: '',
            months: ''
          }
        )
        getdata();
      } else {
        setLoading(false)
      }
    }

  }

  const deleteData = async (id) => {
    let result = ""
    setLoading(true)
    if (id) {
      let param = {
        status: 'archived'
      }
      result = await directUSAPICall().items('lifecycle_settings').deleteOne(id);
      setTimeout(() => {
        getdata();
        setLoading(false)
      }, 500)
    }

  }

  const edit = (item) => {
    setEditMode(true)
    setValues(item)
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='lg'
        className='dialogRoot'
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle id="alert-dialog-title text-uppercase">Lifecycle</DialogTitle>
        <DialogContent>

          <form
            autoComplete="off"
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
          // onSubmit={handleSubmit}
          >
            <div className="card card-custom gutter-b">
              <div className='card-body'>
                <div className="form-group row">

                  <div className='col-lg-6'>
                    <TextField
                      value={values && values.equipment ? PRODUCTCATEGORIES.find(v => v === values.equipment) || {} : null}
                      label="Equiplment"
                      id="equipment"
                      name="equipment"
                      select
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant={"standard"}
                      className={classes.textField}
                      value={values?.equipment}
                      onChange={handleChange('equipment')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                    >
                      {PRODUCTCATEGORIES.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className='col-lg-6'>
                    <TextField
                      variant={"standard"}
                      id="quanat"
                      name="months"
                      label="Months"
                      type={'number'}
                      className={classes.textField}
                      value={values?.months}
                      onChange={handleChange('months')}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='card-body pt-2'>
            {
              datas?.length > 0 ? datas.map((item, key) => (
                <div className="form-group row" key={key} style={{ marginBottom: '.6rem' }}>

                  <div className='col-lg-4'>
                    {item.equipment}
                  </div>
                  <div className='col-lg-2'>
                    {item.months}
                  </div>
                  <div className='col-lg-2'>
                    <i className="fas fa-edit" onClick={() => edit(item)} />
                    <i className="fas fa-trash-alt" onClick={() => deleteData(item.id)} />
                  </div>
                </div>
              )) : null
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => handleClose(false)}
            color='secondary'
            // disabled={loading}
            className={classes.button}
          >
            Cancel
            </Button>
          {
            <Button
              variant='contained'
              onClick={() => submit()}
              color='primary'
              // disabled={loading || !enableSubmission}
              className={classes.button}
            >
              {values?.id ? 'Update' : 'Submit'}
              {loading && (
                <CircularProgress
                  size='1rem'
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}

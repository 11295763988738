import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import {
  CloseSquareOutlined,
  CheckSquareFilled
} from '@ant-design/icons';
import Button from '@material-ui/core/Button';

export default (props) => {
  const [columnDefs, setcolumnDefs] = useState(null);
  const [tempColumnDefs, setTempColumnDefs] = useState([])
  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }
  //   // if (!open) {
  //   //   submitFilter()
  //   // }
  //   setState({ ...state, [anchor]: open });
  // };

  // const icon = <CloseSquareOutlined fontSize="small" />;
  // const checkedIcon = <CheckSquareFilled fontSize="small" />;

  const setCheckbox = (item, i) => {
    // console.log("iiiiiiiii",item)
    let temp = columnDefs;
    item.checked = !item.checked;
    temp[i] = item;
    // props.api.addEventListener("modelUpdated", updateTotals);

    setcolumnDefs(temp);
  }


  useEffect(() => {
    props.api.addEventListener("modelUpdated", updateTotals);

    // return () => {
    //   if (!props.api.isDestroyed()) {
    //     props.api.removeEventListener("modelUpdated", updateTotals);
    //   }
    // };
  }, []);

  const updateTotals = () => {

    if (props?.api) {
      let tempCol = props?.api?.columnModel?.columnDefs;
      setcolumnDefs(tempCol);
    }
  };

  console.log("columnddefff", columnDefs)


  const icon = <CloseSquareOutlined fontSize="small" size={10} />;
  const checkedIcon = <CheckSquareFilled fontSize="small" size={10} />;

  return (
    <List className="ag-sidebar">
      {columnDefs && columnDefs.map((option, index) => (
        <ListItem key={index}>
          {
            option.field !== 'actions' ? <Button type="text" disableFocusRipple disableElevation
              disabled={option.field == 'actions' ? true : false}
              onClick={() => { setCheckbox(option, index) }}
              style={{ width: '100%', justifyContent: 'flex-start' }}
              startIcon={option.checked ? icon : checkedIcon}
            >
              {option.headerName}
            </Button> : null
          }

        </ListItem>
      ))}
    </List>
  );
};
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { Directus } from '@directus/sdk';
import { API_URL } from '../../../../_metronic/helpers/components/constant';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useLocation } from "react-router";
import { Link } from 'react-router-dom'
import { requestPassword, getbankidtoken, getBankidQR, loginnew, getbankidGetstatus } from '../redux/AuthCRUD'
// import TwoFactorDialog from './Twofactor';
// import { directUSAPICall, directUSAPIToken } from "../../../../_metronic/helpers/components/utils"
import PageLoader from '../../../../_metronic/helpers/components/PageLoader';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  otp: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(6, 'Maximum 6 characters')
  // .required('otp is required'),
})
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
})

const initialValues = {
  email: '',
  password: '',
  otp: ''
}


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginNew() {
  const [loading, setLoading] = useState(false)
  const [userType, setUserType] = useState('PARTNER')
  const dispatch = useDispatch()
  const directus = new Directus(API_URL);
  const location = useLocation();
  const [isForgetPassword, setIsForgetPassword] = useState(false);

  const [page, setPage] = useState('');
  const [errStatus, setErrStatus] = useState(null);
  const [showBankid, setShowBankid] = useState(false);
  const [qrimage, setQrimage] = useState(null);
  const [resetAuth, setResetAuth] = useState(1);
  const [orderRef, setOrderRef] = useState(null);
  const [orderStatus, setOrderStatus] = useState(false);
  const [counter, setCounter] = useState(0);
  const [resumeCount, setResumeCount] = useState(0);
  const [showBankidMobile, setShowBankidMobile] = useState(false);
  const [showBankidWeb, setShowBankidWeb] = useState(false);
  const [signStatus, SetSignStatus] = useState(false);
  const [retry, setRetry] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(0);
  const [orderToken, setOrderToken] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [isBankidLogin, setisBankidLogin] = useState(false);
  const [isEmailLogin, setisEmailLogin] = useState(false);
  const [showHomePage, setshowHomePage] = useState(true);

  useEffect(() => {
    if (location) {
      let currentpage = location.pathname.split("/")
      setPage(currentpage[1])
    }
  }, [location]);

  useEffect(() => {
    if (location && location.pathname === '/auth/login') {
      dispatch(auth.actions.logout());
      // setUserType('SUPERADMIN');
    }

  }, [location])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        loginnew(values.email, values.password)
          .then(({ data: { data } }) => {
            setLoading(false)
            directus.auth.login({
              email: values.email,
              password: values.password
            });
            // localStorage.setItem("userType", userType)
            dispatch(auth.actions.login(data.access_token))
            setLoginLoading(true)
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    if (resetAuth != 0 && resumeCount < 20 && orderStatus && orderRef && (showBankidMobile || showBankidWeb)) {
      if (counter <= 15) {
        const interval = setInterval(() => {
          setCounter((prevCounter) => prevCounter + 1);
          // setLoading(true)
          if (showBankidMobile) {
            callbankidqrimage(orderRef)
          }
          refetchStatus()

        }, 2000);
        return () => clearInterval(interval);
      } else {
        setRetry(true);
        setRefreshStatus((prevRefreshStatus) => prevRefreshStatus + 1);
        setResumeCount((prevResumeCount) => prevResumeCount + 1);
        setCounter(0);
        setOrderRef(null);
        setOrderStatus(false);
        setResetAuth((resetAuth) => resetAuth + 1);
      }
    }

  }, [orderStatus, counter, resetAuth, showBankidMobile, showBankidWeb]);

  const callbankidref = async (type: any) => {
    setShowBankid(false)
    if (type === 'web') {
      setShowBankidWeb(true);
    } else {
      setShowBankidMobile(true);
    }
    await getbankidtoken()
      .then(async (result: any) => {
        // setStatus("We've sent you a secure link to reset your password")
        if (result?.data?.id) {
          localStorage.setItem('orderRef', result.data?.id);
          localStorage.setItem('startToken', result.data?.token);
          setOrderRef(result.data?.id);
          setOrderToken(result.data?.token)
          setOrderStatus(true);
          setRefreshStatus(1);
          setRetry(false);
          if (type === 'mobile' && !orderRef) {
            callbankidqrimage(result.data?.id)
          }
        }
      })
      .catch(() => {
        setShowBankid(false)
        // setSubmitting(false)
        // setStatus("Failed to sent you a secure link to reset your password")
      })
  }

  const callbankidqrimage = async (id: any) => {
    await getBankidQR(id)
      .then(async (result: any) => {
        // setStatus("We've sent you a secure link to reset your password")
        if (result?.data?.qr) {
          setQrimage(result?.data?.qr);
          setShowBankid(false)
          setLoading(false)
        }
      })
      .catch(() => {
        setShowBankid(false)
        // setSubmitting(false)
        // setStatus("Failed to sent you a secure link to reset your password")
      })
  }

  const refetchStatus = () => {
    getbankidGetstatus(orderRef)
      .then(async (result: any) => {
        // setStatus("We've sent you a secure link to reset your password")
        setLoading(false)
        // let token = 'mb1I1HJW_xJlLM3P0dRFsnaHUrMDxAj327VEUtm8po1KKrkX4n2PsgQeKqtGGINn'

        if (result?.data?.CODE == 300) {
          setResetAuth(0);
          directus.auth.login({
            email: result?.data?.email,
            password: result?.data?.password
          });
          setLoginLoading(true)

          dispatch(auth.actions.login(result?.data?.TOKEN))
          // startShadowing(result?.data?.TOKEN);
        }

        if (result?.data?.CODE == 201) {
          setResetAuth(0);
          if (result?.data?.DATA?.personalNumber) {
            //localStorage.setItem('personalnumber', data?.DATA);
            localStorage.setItem('personalDetails', JSON.stringify(result?.data?.DATA));
            // router.push("/signup");
            directus.auth.login({
              email: result?.data?.email,
              password: result?.data?.password
            });
            setLoginLoading(true)

            dispatch(auth.actions.login(result?.data?.TOKEN))

          }

          //router.push("/signup");
          //alert(data?.DATA);
        }
        if (result?.data?.CODE == 200 && result?.data?.DATA?.StatusMessage != "complete" && result?.data?.DATA?.StatusMessage != null) {
          setRefreshStatus((refreshStatus) => refreshStatus + 1);
        }


        // if(data?.CODE == 200 ){
        // 	setResetAuth(0);
        // 	console.log('data@200', data);
        // 	//let str = data?.DATA?.Response?.CompletionData?.user?.personalNumber;		
        // 	//setPersonalNumber(str);
        // }else{
        // 	setRefreshStatus((refreshStatus) => refreshStatus + 1);
        // }


        if (result?.data?.CODE == 200 && result?.data?.DATA?.StatusMessage == null) {
          setRetry(true);
          setResetAuth(0);
          setLoginLoading(false)

        } else if (result?.data?.CODE == 200 && result?.data?.DATA?.Response?.HintCode == "userSign") {
          SetSignStatus(true);
        } else {
          SetSignStatus(false);
        }
      })
      .catch(() => {
        setLoading(false)
        setLoginLoading(false)
        // setSubmitting(false)
        // setStatus("Failed to sent you a secure link to reset your password")
      })
  }

  useEffect(() => {
    if (refreshStatus && orderRef) {
      refetchStatus();
    }
  }, [refreshStatus && orderRef]);



  const formikreset = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        setTimeout(() => {
          localStorage.setItem("email", values.email)
          requestPassword(values.email)
            .then(({ data }) => {
              setStatus("We've sent you a secure link to reset your password")
              setLoading(false)
            })
            .catch(() => {
              setLoading(false)
              setSubmitting(false)
              setStatus("Failed to sent you a secure link to reset your password")
            })
        }, 1000)

      } catch (err) {
        setLoading(false)
        setStatus("Failed to sent you a secure link to reset your password")

        throw err;

      }

      // setTimeout(() => {
      //   resetPassword(values.email)
      //     .then(({ data: { result } }) => {
      //       console.log("result",result)
      //       setLoading(false)
      //     })
      //     .catch(() => {
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })

  // const reset = () => {
  //   setIsForgetPassword(false);
  //   setLoading(false)
  //   setLoginDetails({
  //     isTFAEnable: null,
  //     otpauth_url: '',
  //     tfa_secret: '',
  //     otp: '',
  //     password: '',
  //     email: '',
  //     access_token: ''
  //   });
  //   setOtpLoading(false);
  // }



  return (
    <>
      <PageLoader pageLoaderOpen={loginLoading} setPageLoaderOpen={setLoginLoading} />
      <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{ background: '#456A76' }}>
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <a className="flex-column-auto mt-5 pb-lg-0 pb-10" href="#">
              <img alt="Logo" className="max-h-70px" src="../media/logos/logo-white.png" />
            </a>
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-white">Asset Management System</h3>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/* begin::Heading */}
              {
                <div className='text-center mb-10'>
                  {

                    (showBankid || showBankidMobile || showBankidWeb) ? <h1 className='text-dark mb-3'>{'Sign in with BankID'}</h1> :
                      <h1 className='text-dark mb-3'>{isForgetPassword ? 'Reset password' : 'Sign In to AMS'}</h1>
                  }

                </div>
              }

              {/* Login Section */}
              {
                showHomePage &&
                <div className='bankid-signin'>
                  <div className='fv-row mb-10 bankid-btns'>
                    <Button
                      onClick={() => {
                        setisBankidLogin(true);
                        setshowHomePage(false)
                      }}
                      color="secondary"
                    >
                      <span className='ant-typography txt-color ml-10'>Login with BankID</span>
                    </Button>
                  </div>
                  <div className='fv-row mb-10 bankid-btns'>
                    <Button
                      // variant="contained"
                      onClick={() => {
                        setisEmailLogin(true);
                        setshowHomePage(false)
                      }}
                      color="secondary"
                    >
                      <span className='ant-typography txt-color ml-10'>Login with E-Mail</span>
                    </Button>
                  </div>
                </div>
              }

              {/* begin::Heading */}
              {
                isEmailLogin && !isForgetPassword &&
                <form
                  className='form w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {formik.status || errStatus ? (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                  ) : null}

                  {/* begin::Form group */}
                  <div className='fv-row mb-10'>
                    {/* <label className='form-label fs-6 fw-bolder text-dark'>Email</label> */}
                    <input
                      placeholder='Email'
                      // onChange={(e)=>setEmail(e)}
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}

                  {/* begin::Form group */}
                  <div className='fv-row mb-10'>
                    <div className='d-flex justify-content-between mt-n5'>
                      <div className='d-flex flex-stack mb-2'>
                      </div>
                    </div>
                    <input
                      type='password'
                      placeholder='password'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                      <Link
                        to='#'
                        onClick={() => { setIsForgetPassword(true); setisBankidLogin(false); setisEmailLogin(false) }}
                        className='link-primary fs-6 fw-bolder'
                        style={{ marginLeft: '5px' }}
                      >
                        Forgot Password ?
                      </Link>
                    </div>
                  </div>
                  {/* end::Form group */}

                  {/* begin::Action */}
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Link to="#"
                      onClick={() => {
                        setshowHomePage(true);
                        setisEmailLogin(false);
                      }}>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-lg btn-light-danger fw-bolder bankid-cancel'
                      // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Cancel
                        </button>
                    </Link>{' '}
                    <a className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot" href="#"></a>
                    <button id="kt_login_signin_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span>
                      {!loading && <span className='indicator-label'>{isForgetPassword ? 'Reset' : 'Sign In'}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}</span>
                    </button>
                  </div>
                </form>
              }
              {
                isForgetPassword &&
                <form
                  className='form w-100'
                  onSubmit={formikreset.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {formikreset.status ? (
                    <div className='mb-lg-15 alert alert-success'>
                      <div className='alert-text font-weight-bold'>{formikreset.status}</div>
                    </div>
                  ) : null}

                  {/* begin::Form group */}
                  <div className='fv-row mb-10'>
                    {/* <label className='form-label fs-6 fw-bolder text-dark'>Email</label> */}
                    <input
                      placeholder='Email'
                      {...formikreset.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formikreset.touched.email && formikreset.errors.email },
                        {
                          'is-valid': formikreset.touched.email && !formikreset.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formikreset.touched.email && formikreset.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formikreset.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}
                  {/* begin::Action */}
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Link to="#"
                      onClick={() => {
                        setisEmailLogin(true);
                        setshowHomePage(false)
                        setIsForgetPassword(false)
                      }}>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-lg btn-light-primary fw-bolder'
                      // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Signin
                    </button>
                    </Link>{' '}
                    <button id="kt_login_signin_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span>
                      {!loading && <span className='indicator-label'>{isForgetPassword ? 'Reset' : 'Sign In'}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}</span>
                    </button>
                  </div>
                </form>
              }
              {
                isBankidLogin &&
                <div className='bankid-signin'>
                  <div className='fv-row mb-10 bankid-btns'>
                    <Button
                      onClick={() => {
                        setLoading(true)
                        callbankidref('web');
                        setshowHomePage(false)
                        setisBankidLogin(false)
                      }}
                      color="secondary"
                      endIcon={
                        <img
                          className="image"
                          width="30"
                          src="../media/logos/bankid.svg"
                          alt="bottomlogo"
                        />
                      }
                    >
                      <span className='ant-typography txt-color ml-10'>BankID on same device</span>
                    </Button>
                  </div>
                  <div className='fv-row mb-10 bankid-btns'>
                    <Button
                      // variant="contained"
                      onClick={() => {
                        setLoading(true)
                        callbankidref('mobile');
                        setshowHomePage(false);
                        setisBankidLogin(false)
                      }}
                      color="secondary"
                      endIcon={
                        <img
                          className="image"
                          width="30"
                          src="../media/logos/bankid.svg"
                          alt="bottomlogo"
                        />
                      }
                    >
                      <span className='ant-typography txt-color ml-10'>BankID on Mobile</span>
                    </Button>
                  </div>
                  <div className="cancelbtn">
                    <Link to="#"
                      onClick={() => {
                        setOrderRef(null);
                        setOrderStatus(false);
                        setRefreshStatus(0);
                        setQrimage(null);
                        setShowBankid(false);
                        setisBankidLogin(false);
                        setisEmailLogin(false)
                        setshowHomePage(true);
                      }}>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-lg btn-light-danger fw-bolder bankid-cancel'
                      // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Back to Home page
                        </button>
                    </Link>{' '}
                  </div>
                </div>
              }

              {
                showBankidMobile && <form
                  className='form w-100'
                  // onSubmit={formikotp.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {/* {formikotp.status ? (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{formikotp.status}</div>
                    </div>
                  ) : null} */}
                  {

                    <div className="form-group row">
                      <div className="bankid-logo">
                        <img
                          className="image"
                          width="80"
                          src="../media/logos/bankid.svg"
                          alt="bottomlogo"
                        />
                        <h4 className="title">Identify with BankID</h4>

                      </div>

                      <div className='col-lg-12'>
                        <div className="qrcode1">
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                          {qrimage && <img src={qrimage} alt="bottomlogo" width={240} />}
                        </div>
                      </div>
                      <div className="desc">
                        1. Open the bankID app and click on the QR icon.
                        <br />
                          2. Point the camera at the QR code shown here on the screen.

                        </div>
                    </div>
                  }

                  {/* begin::Action */}
                  <div className="cancelbtn">
                    <Link to="#"
                      onClick={() => {
                        setOrderRef(null);
                        setOrderStatus(false);
                        setRefreshStatus(0);
                        setQrimage(null);
                        setShowBankid(true);
                        setShowBankidMobile(false);
                        setShowBankidWeb(false);
                        setisBankidLogin(true)
                      }}>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-lg btn-light-danger fw-bolder bankid-cancel'
                      // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Cancel
                        </button>
                    </Link>{' '}
                  </div>
                </form>
              }

              {
                showBankidWeb && <form
                  className='form w-100'
                  // onSubmit={formikotp.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {/* {formikotp.status ? (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{formikotp.status}</div>
                    </div>
                  ) : null} */}
                  {

                    <div className="form-group row">
                      <div className='bankid-signin'>
                        <div className='col-lg-12'>
                          <div className='fv-row mb-10 bankid-btns'>
                            <Button
                              // variant="contained"
                              // onClick={() => {
                              //   setLoading(true)
                              //   callbankidref('mobile');
                              // }}
                              color="secondary"
                              target="_blank"
                              href={"bankid:///?autostarttoken=" + orderToken + "&redirect=null"} //+process.env.NEXT_PUBLIC_APP_URL+"loginbankid?id=1"}

                              endIcon={
                                <img
                                  className="image"
                                  width="30"
                                  src="../media/logos/bankid.svg"
                                  alt="bottomlogo"
                                />
                              }
                            >
                              <span className='ant-typography txt-color ml-10'>Start the BankID</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {/* begin::Action */}
                  <div className="cancelbtn">
                    <Link to="#"
                      onClick={() => {
                        setOrderRef(null);
                        setOrderStatus(false);
                        setRefreshStatus(0);
                        setQrimage(null);
                        setShowBankid(true);
                        setShowBankidMobile(false);
                        setShowBankidWeb(false);
                        setisBankidLogin(true)
                      }}>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-lg btn-light-danger fw-bolder bankid-cancel'
                      // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Cancel
                        </button>
                    </Link>{' '}
                  </div>
                </form>
              }
              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

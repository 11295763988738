
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Check from '@material-ui/icons/Check';
// import { PageTitle } from '../../../../_metronic/layout/core'
import moment from "moment";
import { CreateAsset, UpdateAsset, getassetsList } from '../redux/AssetsCrud';
import { cleanEmptyObj } from '../../../../_metronic/helpers/components/utils'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import CustomCancelButton from "../../../../_metronic/helpers/uikit/CancelButton";
import CircularProgress from '@mui/material/CircularProgress'
import { ASSETSFIELDS, ASSETAGGREMENTFIELDS, ASSETSFIELDS1 } from "../../../../_metronic/helpers/components/constant"
import { directUSAPICall, groupByAgreement } from "../../../../_metronic/helpers/components/utils"
import { SVRemovePercentageformatter, getMonthDiff } from '../../../../_metronic/helpers/components/utils'
import { getAgreementStatus } from '../../../../_metronic/helpers/components/calculation'

import { useSelector } from "react-redux";
// import FilterSelection from '../../../../_metronic/helpers/components/Filter';

const useStyles = makeStyles(theme => ({
  labelContainer: {
    "& $alternativeLabel": {
      fontSize: '20px'
    }
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Agreement Info', 'Asset Info', 'Finance Info', 'User Info', 'Notes'];
}

export default function AssetCreateForm({
  fieldDisable,
  searchAssetId,
  setShowAssetCreate,
  handleAssetsList,
  userPermission,
  currentpage
}) {
  const classes = useStyles();
  const history = useHistory()
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const [agreementList, setAgreementList] = useState([]);
  const urlParams = useParams()
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState("")
  const [agreementData, setAgreementData] = useState("");
  const [assetTypes, setAssetTypes] = useState([]);
  const [values, setValues] = useState({
    id: '',
    date_of_delievery_approval: null,
    insurance_until: null,
    warrenty_until: null,
    ref_document: null,
    ccmanager_name: null,
    ccmanager_email: null,
    ccmanager_phone: null,
    cost_centre2: null,
    cost_centre1: null,
    cost_centre: null,
    user_invoice_address: null,
    user_country: null,
    user_devision: null,
    user_city: null,
    employee_no: null,
    user_address: null,
    email_phoneno: null,
    user_email: null,
    user_name: null,
    agrement_info_no: null,
    partno: null,
    asset_status: null,
    supplier_invoice_reference: null,
    Client_ref_number: null,
    prod_comments: null,
    agreement_extra_field1: null,
    exit_options: null,
    extra_infor2: null,
    extra_infor1: null,
    project: null,
    agreement_extra_field2: null,
    pickup_point: null,
    extra: null,
    IMEI: null,
    insurance_company: null,
    description: null,
    age: null,
    screen_size: "",
    harddisk: "",
    memory: "",
    processor: "",
    serial_number: "",
    model: "",
    brand: "",
    product_description: "",
    client_own_product_group: "",
    product_group: "",
    asset_type: "",
    status: "published",
    price: "",
    priceper_unit: null,
    rent: "",
    price_total: null,
    dailyrent: null,
    rent_period: null,
    no_of_quantity: null,
    Interim_rent_cost: null,
    upcomingrents: null,
    note: null,
    notes: [],
    comments: null,
    Interim_no_of_days: null,
    agreement: {},
    client: null,
    partner: null,
    founding_partner: null
  });
  const steps = getSteps();
  const { data } = useSelector(({ auth }) => auth.user);
  const [userInfo, setUserInfo] = useState(data);
  const [isDisable, setIsDisable] = useState(false);
  const [isUserDisable, setUserIsDisable] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [loggedUserRole, setLoggedUserRole] = useState();
  const [hideColumns, setHideColumns] = useState(false);
  const [showFields, setShowFields] = useState(false)

  useEffect(() => {
    if (userInfo) {
      if (userInfo && userInfo.role?.name === 'admin_user' || userInfo.role?.name === 'master_admin') {
        setShowFields(true)
      } else {
        setIsDisable(true)
        if (currentpage === 'view') {
          setUserIsDisable(true)
        }
        if (currentpage === 'edit') {
          setUserIsDisable(false)
        }
      }
      //  else {

      // }
    }
  }, [userInfo, userPermission, currentpage])

  // useEffect(() => {
  //   if (currentpage && userPermission) {

  //   }
  // }, [userPermission, currentpage])


  function totalSteps() {
    return getSteps().length;
  }

  // useEffect(() => {
  //   if (pathname) {
  //     // groupByAgreement('', 'create')
  //     const path_name = pathname.split("/")
  //     // setCurrentPage(path_name[1])

  //     console.log("current page", path_name[1])
  //     if(path_name[1] === 'createasset' || path_name[1] === 'assets') {
  //       lists()
  //     } else {

  //     }

  //   }
  // }, [pathname])

  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (searchAssetId) {
      setLoading(true);
      getAssetDetailData(searchAssetId)
      setActiveStep(1)
    }

  }, [searchAssetId])

  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (urlParams && urlParams.id) {
      lists(urlParams.id)
    } else {
      lists()
    }

  }, [urlParams])

  const getassetTypes = async () => {

    let options = {
      limit: -1,
      sort: 'asset_name',
      fields: ['asset_name,fields']
    }
    const result = await directUSAPICall().items('asset_type').readMany(options);
    if (result.data && result.data.length > 0) {
      // console.log("result",result.data)
      let assetNames = result.data.map(
        (assetType) => assetType.asset_name
      );
      setAssetTypes(assetNames);

    } else {
      setAssetTypes([])
    }
  }

  const getAssetDetailData = async (id) => {
    setLoading(false)
    let fields = `${ASSETSFIELDS},${ASSETSFIELDS1},${ASSETAGGREMENTFIELDS},notes,aggrement.partnersalesperson`
    const result = await directUSAPICall().items('Asset').readOne(id, { fields: [fields] });
    if (result && result.id) {
      let agData = result.aggrement
      setLoading(false)
      if (agData?.partner && agData?.client) {
        agData.partnerid = agData.partner ? agData.partner.id : null
        agData.clientid = agData.client ? agData.client.id : null

        agData.partner = agData.partner.partner_name + ' - ' + agData.partner.partner_org_no
        agData.client = agData.client.name + ' - ' + agData.client.clients_org_no
        if (agData.founding_partner) {
          let sup = agData.founding_partner;
          agData.founding_partner = agData.founding_partner.id
          agData.founding_partner = sup.supplier_name + ' - ' + sup.supplier_org_no
        }
      }

      if (agData?.ag_status && agData?.lease_start_date) {
        let statusList = ["EXTENDED"]
        let monthDiff = getMonthDiff(agData?.eol_date);
        let ag_status = getAgreementStatus(agData, monthDiff).status || ''
        if (!statusList.includes(agData?.ag_status)) {
          agData.ag_status = ag_status;
        }
      }
      agData.lease_start_date = agData.lease_start_date ? moment(agData.lease_start_date).format('YYYY-MM-DD') : null;
      agData.eol_date = agData.eol_date ? moment(agData.eol_date).format('YYYY-MM-DD') : null
      result.date_of_delievery_approval = result.date_of_delievery_approval ? moment(result.date_of_delievery_approval).format('YYYY-MM-DD') : null
      result.partner_sales_person = result.partner_sales_person ? result.partner_sales_person : agData?.partnersalesperson;
      // setAgreementValues(agData)
      result.aggrement = agData;
      setAgreementData(agData)

      setValues(result)
    } else {
      setLoading(false)
    }
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1
          agreementList={agreementList}
          values={values}
          isDisable={isDisable}
          setValues={setValues}
          agreementData={agreementData}
          setAgreementData={setAgreementData}
          urlParams={urlParams}
          showFields={showFields}
          isDisable={isDisable}
        />;
      case 1:
        return <Step2
          values={values}
          setValues={setValues}
          assetTypes={assetTypes}
          isDisable={isDisable}
          agreementData={agreementData}
          userInfo={userInfo}
          hideColumns={hideColumns}
        />;
      case 2:
        return <Step3
          values={values}
          isDisable={isDisable}
          setValues={setValues}
          currentPage={currentPage}
          loggedUserRole={loggedUserRole}
          hideColumns={hideColumns}
          showFields={showFields}
        />;
      case 3:
        return <Step4
          values={values}
          setValues={setValues}
          isDisable={isDisable}
          currentPage={currentPage}
          isUserDisable={isUserDisable}
          hideColumns={hideColumns}
        />;
      case 4:
        return <Step5
          setDescription={setDescription}
          isDisable={isDisable}
          values={values}
          setValues={setValues}
        />;
      default:
        return 'Unknown step';
    }
  }

  function isStepOptional(step) {
    return step === 1;
  }


  function skippedSteps() {
    return skipped.size;
  }

  function completedSteps() {
    return completed.size;
  }

  function allStepsCompleted() {
    return completedSteps() === totalSteps() - skippedSteps();
  }

  function isLastStep() {
    return activeStep === totalSteps() - 1;
  }

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleStep = step => () => {
    setActiveStep(step);
  };

  function handleReset() {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function isStepComplete(step) {
    return completed.has(step);
  }

  useEffect(() => {
    if (data && data?.id && data.role) {
      setLoggedUserRole(data.role.name)

      setUserInfo(data)
      // lists()
      getassetTypes()
    }
  }, [data])

  const lists = async (agid = null) => {
    let options = {
      limit: -1,
      sort: '-id',
      fields: ['id,agrement_info_no,heading']
    }
    setLoading(true)
    if (userInfo.role) {
      if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {
        setHideColumns(true)
        // options.filter = {
        //   "client.id": {
        //     _eq: userInfo.client.id
        //   }
        // }
        options.filter = {
          "user_created": {
            "client": {
              _eq: userInfo.client.id
            }
          }
        }
      }
      if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
        // console.log("users_details", users_details)
        options.filter = {
          "partner.id": {
            _eq: userInfo.partner.id
          }
        }
      }
    }
    if (agid) {
      options.filter = {
        _and:
          [
            {
              "id": {
                _eq: agid
              }
            }
          ]
      }
    }

    const result = await directUSAPICall().items('aggrement').readMany(options);
    if (result.data && result.data.length > 0) {
      setAgreementList(result.data)
      // if (agid && result?.data[0]?.agrement_info_no) {
      //   console.log("agremeent darta", (result.data))

      //   // agreementData.agrement_info_no = JSON.parse(result.data)[0]['agrement_info_no'];

      //   // setAgreementData({ ...agreementData })
      // }

      setLoading(false)

    } else {
      setAgreementList([])
      setLoading(false)

    }
  }


  const handleSubmit = (type) => {
    // let des = ''
    if (!description) {
      values.notes = ''
    }
    if (values.aggrement) {
      if (values.aggrement.id) {
        values.client = values.aggrement.clientid
        values.partner = values.aggrement.partnerid
        if (values.aggrement.supplierid) {
          values.founding_partner = values.aggrement.supplierid
        }
      }
      let tempagrmt = values.aggrement
      values.aggrement = values.aggrement.id || values.aggrement
      delete values.user_updated;
      delete values.user_created;
      delete values.date_created;
      delete values.date_updated;
      values.priceper_unit = values.priceper_unit ? SVRemovePercentageformatter(values.priceper_unit) : null;
      values.price_total = values.price_total ? SVRemovePercentageformatter(values.price_total) : null;
      values.dailyrent = values.dailyrent ? SVRemovePercentageformatter(values.dailyrent) : null;
      values.rent_period = values.rent_period ? SVRemovePercentageformatter(values.rent_period) : null;
      values.Interim_rent_cost = values.Interim_rent_cost ? SVRemovePercentageformatter(values.Interim_rent_cost) : null;
      setLoading(true)
      values.date_of_delievery_approval = values.date_of_delievery_approval ? values.date_of_delievery_approval : null
      // values.date_of_delievery_approval = values.date_of_delievery_approval ? moment(values.date_of_delievery_approval).format('DD/MM/YYYY') : null
      if (!values.id || values.id === 0 && cleanEmptyObj(values)) {

        CreateAsset(cleanEmptyObj(values))
          .then(async ({ data: { data } }) => {
            // console.log("cleanEmptyObj(values)", cleanEmptyObj(values))
            // if(type === 'saveexit') {
            setLoading(false)

            let agrementId = data.aggrement;
            // console.log("idddddd", data.aggrement)

            if (type === 'savenext') {
              // history.push(`/createagreement/${data.id}`)
              delete data.aggrement
              values.aggrement = tempagrmt
              values.id = data.id
              await createNotes(data.id, type);
              setValues({ ...values });
              // groupByAgreement(agrementId, 'create')
              handleNext()
            }
            if (type === 'saveexit') {
              groupByAgreement(agrementId, 'create')
              values.id = data.id
              values.aggrement = tempagrmt

              await createNotes(data.id, type);
              setValues({ ...values });
              handleAssetsList()
              // if (urlParams?.id) {
              //   history.push(`/agrementassets/${urlParams?.id}`);
              // } else {
              //   history.push('/assets')
              // }

            }

            // setAgreementList(data)
            // setIsloading(false)
            //   dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            // setIsloading(false)
            //   setStatus('The login detail is incorrect')
          })
      }
      if (values.id) {
        UpdateAsset(values, values.id)
          .then(async ({ data: { data } }) => {
            // if(type === 'saveexit') {
            setLoading(false)
            let agrementId = data.aggrement;
            // groupByAgreement(agrementId)

            if (type === 'savenext') {
              values.id = data.id;
              createNotes(data.id, type)

              setValues({ ...values });
              handleNext()
            }

            if (type === 'saveexit') {
              values.id = data.id
              setValues({ ...values });
              await createNotes(data.id, type);
              handleAssetsList()
              setShowAssetCreate(false);
              // history.push('/assets')
            }
            // setAgreementList(data)
            // setIsloading(false)
            //   dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            // setIsloading(false)
            //   setStatus('The login detail is incorrect')
          })
      }


    }

  }


  const createNotes = async (id, type) => {
    let result = "";
    if (description) {
      let param = {
        description: description,
        selected_id: id,
        collection: "Asset"
      }
      result = await directUSAPICall().items('notes').createOne(param);
    }
    if (type === 'saveexit') {
      if (urlParams?.id) {
        history.push(`/agrementassets/${urlParams?.id}`);
      } else {
        history.push('/assets')
      }
    }
  }

  return (
    <>
      <div>
        {/* <FilterSelection
          title={`${values.id ? 'Modify' : 'Create'} Asset`}
          redirect="assets"
        /> */}
      </div>
      <div className="card card-custom gutter-b bg-smoke">
        <div className='card-body'>
          <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const buttonProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      icon={<Check className={[classes.xiconRoot, index === activeStep ? 'activestep' : ''].join(' ')} />}
                      onClick={handleStep(index)}
                      completed={isStepComplete(index)}
                      {...buttonProps}
                    >
                      <StepLabel
                        classes={{
                          iconContainer: classes.iconContainer
                        }}
                      >
                        {label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
            </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                  <div className="stepper-btn">
                    {
                      !isDisable ?
                        <CustomCancelButton
                          variant="secondary"
                          color="danger"
                          to='assets'
                        /> : null}

                    <Button variant="contained"
                      color="warning" disabled={activeStep === 0} onClick={handleBack} className={[classes.button, 'backbtn']}>
                      Back
              </Button>
                    {
                      (currentpage !== 'view') && (!isDisable || !isUserDisable) ? <>

                        {activeStep < 4 && <Button
                          onClick={() => handleSubmit('savenext')}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                          <span className='indicator-label'>{'Save & Next'}</span>
                          {
                            loading && <CircularProgress />
                          }

                        </Button>}

                        <Button
                          onClick={() => handleSubmit('saveexit')}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                          <span className='indicator-label'>{'Save & Exit'}</span>
                          {
                            loading && <CircularProgress />
                          }

                        </Button></> : <Button
                          onClick={() => handleNext()}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                        <span className='indicator-label'>{'Next'}</span>
                      </Button>
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

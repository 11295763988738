/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Inventory from '../../modules/assets/purchased_index';
import { useDispatch, useSelector } from "react-redux";
import { directUSAPICall, SVformatter, getQuarter } from '../../../_metronic/helpers/components/utils';
import PageLoader from "../../../_metronic/helpers/components/PageLoader";
import _ from "lodash";
// import { Column, G2 } from '@ant-design/charts';
import { Redirect, Link } from 'react-router-dom'
import * as auth from '../../modules/auth/redux/AuthRedux';
import moment from "moment";
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import PieChart, {
  Legend,
  Series,
  Label,
  Connector,
  Size,
  Font,
} from 'devextreme-react/pie-chart';

import { Column } from '@ant-design/plots';


const formatText = (arg) => {
  return `${SVformatter(arg.valueText) + ' Kr'} (${arg.percentText})`;
}

const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + 'B';
  } else if (number > 1000000) {
    return (number / 1000000).toString() + 'Mkr';
  } else if (number > 1000) {
    return (number / 1000).toString() + 'Tkr';
  } else {
    return number.toString();
  }
}

const pointClickHandler = (e) => {
  window.open(`/inventory-suppliers/${e.target.data.name}`, '_blank', 'noreferrer');
}
const onPointHoverChanged = (e) => {
  if (e.target.isHovered()) {
    e.element.style.cursor = "pointer"
  } else {
    e.element.style.cursor = "auto"
  }
}

const DashboardPage = ({
  inventoryDetails,
  top3Suppliers,
  productGroup,
  unitsTobereplacedChart,
  setTotalAssets,
  productGroupconfig,
  supplierBarChartconfig,
  noofsuppliers,
  recentPurchase,
  values,
  rowData
}) => (
  <>
    {/* begin::Row */}

    <div className='row gy-5 g-xl-8 overview'>
      <div className='col-lg-4 div1'>
        <div className={`card stat detail`}>
          {/* begin::Header */}
          <div className={`card-header border-0 bg-danger`}>
            <h3 className='card-title text-bold MuiTableCell-head'>Overview Inventory</h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body p-0'>
            {/* begin::Chart */}
            <div
              className={`mixed-widget-2-chart card-rounded-bottom bg-danger`}
            ></div>
            {/* end::Chart */}
            {/* begin::Stats */}
            <div className='card-p position-relative card-p1'>
              {/* begin::Row */}
              <div className="table-responsive">
                {/* begin::Table */}
                <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                  {/* begin::Table head */}
                  <tbody>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Number of Assets</td>
                      <td className="p-0 w-5px title-val" >{inventoryDetails?.noofinventory}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Recent purchase date</td>
                      <td className="p-0 w-5px title-val" >{recentPurchase || ''}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Asset value</td>
                      <td className="p-0 w-5px title-val" >{SVformatter(inventoryDetails?.totalValue) + " kr" || ''}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >No of suppliers</td>
                      <td className="p-0 w-5px title-val" >{noofsuppliers || ''}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Units to be replaced next quarter</td>
                      <td className="p-0 w-5px title-val" >{inventoryDetails?.unitsreplacednextquarter}</td>
                    </tr>
                    <tr className="border-0">
                      <td className="p-0 w-5px title" >Units to be replaced next year</td>
                      <td className="p-0 w-5px title-val" >{inventoryDetails?.unitsreplacednextYear}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <div className="card stat">
          <div className={`card-header border-0 bg-danger`}>
            <h3 className='card-title text-bold MuiTableCell-head'>{'Top 3 Suppliers'}</h3>
          </div>
          <div className='card-body'>
            <div>
              {
                top3Suppliers?.length > 0 ?
                  <PieChart
                    id="pie"
                    type="doughnut"
                    palette={["#a95c24", "#456A76", "#808080"]}
                    dataSource={top3Suppliers}
                    innerRadius={0.65}
                    onPointClick={pointClickHandler}
                    onPointHoverChanged={onPointHoverChanged}
                  >
                    <Size
                      width={'100%'}
                      height={'264'}
                    />
                    <Series argumentField="name" valueField="value" >
                      <Label visible={true} customizeText={formatText} backgroundColor="#f8f8f8">
                        <Font size={10} color="#4e4d4d" weight="900" />
                        <Connector visible={true} width={3} color="#e3e3e3" />
                      </Label>
                    </Series>
                    <Legend
                      visible={true}
                      margin={0}
                      horizontalAlignment="center"
                      verticalAlignment="bottom"
                    />

                  </PieChart> :
                  <div><span>No records found.</span></div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-8 div2'>
        <Inventory
          currentpage="overview"
          setTotalAssets={setTotalAssets}
          searchString={values.searchfield}
          inventoryData={rowData}
        />
      </div>
    </div>
    <div className='row gy-5 g-xl-8 overview bottom'>
      <div className='col-lg-4 div1'>
        {
          <div>
            <div className="card stat ">
              <div className={`card-header border-0 bg-danger`}>
                <h3 className='card-title text-bold MuiTableCell-head'>{'Units to be replaced'}</h3>
              </div>
              <div className='card-body'>
                <div>
                  {
                    unitsTobereplacedChart?.length > 0 ? <Column {...supplierBarChartconfig} /> :
                      <div><span>No records found.</span></div>
                  }

                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className='col-lg-8 div2'>
        {
          <div>
            <div className="card stat ">

              <div className={`card-header border-0 bg-danger`}>
                <h3 className='card-title text-bold MuiTableCell-head'>Volume of assets per product category</h3>
              </div>
              <div className='card-body'>
                <div>
                  {
                    productGroup?.length > 0 ? <Column {...productGroupconfig} /> :
                      <div><span>No records found.</span></div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </>
)

export default function OverviewWrapperInventory() {
  const [loading, setLoading] = useState(false)
  const { data } = useSelector(({ auth }) => auth.user);
  const [totalFinanceAggTitle] = useState('');
  const [selectedRows, setSelectedRows] = useState([])
  const [unitsTobereplacedChart, setUnitsTobereplacedChart] = useState([])

  const [userInfo] = useState(data);
  const dispatch = useDispatch()
  const [inventoryDetails, setInventoryDetails] = useState({
    noofinventory: 0,
    recentPurchase: 0,
    noofsuppliers: 0,
    totalValue: 0
  })
  const [values, setValues] = useState({
    partner: null,
    client: null,
    aggrement: null
  })
  const [productGroup, setProductGroup] = useState([]);
  const [userTypes] = useState({
    isClient: false,
    isAdmin: false,
    isPartner: false
  });
  const [top3Suppliers, setTop3Suppliers] = useState([]);
  const [totalAssetsList, setTotalAssets] = useState([]);
  const [suppliers, setSuppliers] = useState([])
  const [isOwnOverview] = useState(null)
  const [recentPurchase, setrecentPurchase] = useState(null)
  const [noofsuppliers, setnoofsuppliers] = useState(null)
  const [rowData, setRowData] = useState([])
  const [resultsFound, setresultsfound] = useState('')

  useEffect(() => {
    if (data && data?.id) {
      localStorage.setItem("globalsearchField", '');
      localStorage.setItem("globalsearchAgreements", '');
      getSuppliers()

      // callAssetsList()
    } else {
      logout()
    }
  }, [data, isOwnOverview])

  useEffect(() => {
    if (suppliers && suppliers?.length > 0) {
      callAssetsList()
    }
  }, [suppliers])

  useEffect(() => {
    if (rowData && rowData?.length > 0) {

      const sortedData = _.sortBy(rowData, function (o) { return o.date_of_purchase }).reverse();
      let nextTimeData = []
      sortedData.forEach((element) => {
        // if (new Date(element.date_of_purchase) > new Date()) {
        nextTimeData.push(element)
        // }
      })
      // console.log("totalAssetsList",nextTimeData)

      setrecentPurchase(nextTimeData?.length > 0 ? moment(nextTimeData[0].date_of_purchase).format('YYYY-MM-DD') : '')


    }
  }, [rowData])

  const logout = () => {
    dispatch(auth.actions.logout())
    {
      (localStorage.getItem("userType") === 'PARTNER' ||
        localStorage.getItem("userType") === 'CLIENT') ? <Redirect to='/login' /> : <Redirect to='/auth/login' />
    }
  }

  const getSuppliers = async () => {

    let options = {
      limit: -1,
      fields: ['id,supplier_name']
    }
    const result = await directUSAPICall().items('supplier').readMany(options);
    if (result.data && result.data.length > 0) {
      setSuppliers(result.data)
      setnoofsuppliers(result.data.length)
    } else {
      setSuppliers([])
    }
  }

  const getSupplierOrgNo = (id) => {
    let response = suppliers.filter((row) => row.id == id)
    return response && response.length > 0 ? response[0].supplier_name : ""
  }

  const callAssetsList = async (searchvalue = null) => {
    setLoading(true)
    let options = {
      limit: -1,
      sort: 'equipment',
      meta: ['total_count', 'filter_count'],

      fields: [
        `end_of_lifecycle,lifecycle_status,lifecycle_months,pid,description,supplier.supplier_name,supplier.id,supplier.supplier_org_no,client.name,client.clients_org_no,quantity,sum_purchase_value,unit_price,date_of_purchase,price_total,description,equipment`,
      ],
      // groupBy: ['equipment'],
      // aggregate: {
      //   sum: 'price_total'
      // },
      filter: {
        _and: [
        ]
      }
    }
    let options1 = {
      limit: -1,
      aggregate: {
        sum: ['price_total']
      }
    }
    if (userInfo.role) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        options.filter['_and'].push({
          "client": {
            _eq: userInfo.client.id
          }
        })
      }

      if (searchvalue) {
        let string = searchvalue.split(" ");
        let filters = {
          _and: []
        }
        string.map((obj) => {
          filters['_and'].push({
            "searchfield": {
              _contains: `-${obj.toLowerCase()}`
            }
          })
        })
        options.filter['_and'].push(filters)
        // options1.filter['_and'].push(filters)
      }
    }
    setLoading(true);
    options1.filter = options.filter
    const result = await directUSAPICall().items('purchased_assets').readMany(options);
    // const result1 = await directUSAPICall().items('purchased_assets').readMany(options1);

    if (result.data && result.data.length > 0) {
      let response = result.data;
      let unitsreplacednextquarter = []
      let unitsreplacednextYear = []
      response.forEach((item) => {
        item.supplier_id = item.supplier ? item.supplier.id : null;
        let nextQuarter = getQuarter() + 1 // to fetch next quarter
        let nextYear = moment().year() + 1 // to fetch next year
        // console.log(item.end_of_lifecycle, "moment(item.end_of_lifecycle", moment(moment(item.end_of_lifecycle)).year())
        if (item.end_of_lifecycle && (nextQuarter === getQuarter(new Date(moment(item.end_of_lifecycle).format('YYYY-MM-DD'))))) {
          unitsreplacednextquarter.push(item)
        }
        if (item.end_of_lifecycle && (nextYear === moment(moment(item.end_of_lifecycle)).year())) {
          unitsreplacednextYear.push(item)
        }
        item.year = moment(moment(item.end_of_lifecycle)).year()
      })
      let total = result.meta;
      // let response1 = result1.data;//fetch total value
      // units to be replaced in year

      let unitstobereplaced = response.filter(
        (itm) => (itm.year)
      );
      var unitstobereplacedcounts = _.countBy(unitstobereplaced, 'year');

      let unitstobereplacedcountsChart = _.map(unitstobereplacedcounts, function (value, key) {
        return {
          name: key,
          value: value
        };
      });
      setUnitsTobereplacedChart(unitstobereplacedcountsChart)

      // product group
      let equipment = _(response)
        .groupBy('equipment')
        .map(group => ({
          name: group[0]?.equipment,
          "value": _.sumBy(group, x => x.price_total ? parseFloat(x.price_total) : 0)
        }))
        .value();
      equipment = equipment.sort((a, b) => b.value - a.value);
      equipment = equipment.slice(0, 10)
      //_________________________________________________________
      //To get pricetotal
      let price_total = _.round(_.sumBy(response, x => x.price_total ? (parseFloat(x.price_total)) : 0), 0)

      setInventoryDetails({
        noofinventory: total.filter_count,
        // noofsuppliers: suppliers.length,
        totalValue: price_total,
        unitsreplacednextquarter: unitsreplacednextquarter.length,
        unitsreplacednextYear: unitsreplacednextYear.length
      })

      //suppliers data
      let suppliersData = _(response)
        .groupBy('supplier_id')
        .map(group => ({
          name: getSupplierOrgNo(group[0]?.supplier_id) || "OTHER",
          "value": _.sumBy(group, x => x.price_total ? parseFloat(x.price_total) : 0)
        }))
        .value();
      suppliersData = suppliersData.sort((a, b) => b.value - a.value);
      suppliersData = suppliersData.slice(0, 10)
      //_________________________________________________________

      let top3Suppliers = [...suppliersData].sort((a, b) => b.value - a.value).slice(0, 3);;

      setTop3Suppliers(top3Suppliers);

      setProductGroup(equipment);
      setRowData(response)
      setLoading(false)
      if (searchvalue && response?.length > 0) {
        setresultsfound('true')
      } if (searchvalue && response?.length === 0) {
        setresultsfound('false')
      }
    } else {
      setLoading(false)
      setRowData([])
      setUnitsTobereplacedChart([]);
      setProductGroup([]);
      setTop3Suppliers([]);
      setInventoryDetails({})
    }

  }

  const productGroupconfig = {
    data: productGroup.sort((a, b) => (a.name) - (b.name)),
    xField: 'name',
    yField: 'value',
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        window.open(`/inventory-equipment/${args[0].data.data.name}`, '_blank', 'noreferrer');
      });
      plot.on('element:hover', (...args) => {
        window.open(`/inventory-equipment/${args[0].data.data.name}`, '_blank', 'noreferrer');
      });
    },

    cursor: 'pointer',
    xAxis: {
      label: {
        style: {
          fontSize: 10,
          fontWeight: 600,
        },
        autoRotate: false,
        autoHide: true,
      },
    },
    label: {
      position: 'top',
      style: {
        fontSize: 10,
        fontWeight: 900,
      },
      content: (originData) => {
        return `${SVformatter(originData.value)} kr`;
      },
    },
    yAxis: {
      label: {
        style: {
          fill: 'black',
        },
        offset: 15,
        formatter: (text) => {
          return `${DataFormater(text)}`;
        },
      },
    },
    columnWidthRatio: 0.8,
    minColumnWidth: 60,
    maxColumnWidth: 80,
    autoFit: true,
    appendPadding: [17, 0, 0, 0],
    color: '#456A76',
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.name} `,
        value: `${SVformatter(datum.value)} kr`,
      }),
    },
  };

  const supplierBarChartconfig = {
    data: unitsTobereplacedChart.sort((a, b) => (a.year) - (b.year)),
    xField: 'name',
    yField: 'value',
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        window.open(`/inventory-byyear/${args[0].data.data.name}`, '_blank', 'noreferrer');
      });
    },
    cursor: 'pointer',

    xAxis: {
      label: {
        style: {
          fontSize: 10,
          fontWeight: 600,
        },
        autoRotate: false,
        autoHide: true,
      },

    },
    label: {
      position: 'top',
      style: {
        fontSize: 10,
        fontWeight: 900,
      },

      content: (originData) => {
        return `${(originData.value)}`;
      },

    },

    yAxis: {

      label: {
        style: {
          fill: 'black',
        },
        offset: 15,
        formatter: (text) => {
          return `${(text)}`;
        },
      },
    },
    columnWidthRatio: 0.8,
    minColumnWidth: 60,
    maxColumnWidth: 80,
    autoFit: true,
    appendPadding: [17, 0, 0, 0],
    color: '#456A76',
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.name} `,
        value: `${(datum.value)}`,
      }),
    },
  };

  const search = async (event) => {
    event.preventDefault();
    if (values.searchfield) {
      localStorage.setItem("globalinventorysearchField", '')
      setValues({ ...values, aggrement: [] })
      // setFilterAgreements([])
      callAssetsList(values.searchfield)
      return;
    } else {
      setValues({ ...values, searchfield: '' })

      callAssetsList()

    }
  }

  return (
    <div className="overview-container">
      <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} />
      <div style={{ width: '100%', background: '#f8f8f8', padding: '12px' }}>
        {
          <><form onSubmit={search}><div className="searchsection">
            <TextField
              label=""
              className="searchbox"
              name="searchfield"
              onChange={(e, option) => {
                setValues({ ...values, searchfield: e.target.value })
              }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment>
                    <IconButton onClick={search}>
                      <SearchIcon>search</SearchIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          </form>
            <div className="no-results">
              {resultsFound === 'true' && <span>Your search request suucessfully found the records</span>}
              {resultsFound === 'false' && <span style={{ color: 'red' }}>Your search request does not found the records</span>}
            </div>
          </>
        }
      </div>

      <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
      </div>
      {/* {productGroup?.length > 0 && <DemoColumn />} */}
      <DashboardPage
        userDetail={userInfo}
        totalAssets={totalAssetsList}
        productGroup={productGroup}
        unitsTobereplacedChart={unitsTobereplacedChart}
        top3Suppliers={top3Suppliers}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        inventoryDetails={inventoryDetails}
        totalAssetsList={totalAssetsList}
        totalFinanceAggTitle={totalFinanceAggTitle}
        values={values}
        setLoading={setLoading}
        callAssetsList={callAssetsList}
        productGroupconfig={productGroupconfig}
        supplierBarChartconfig={supplierBarChartconfig}
        userTypes={userTypes}
        setTotalAssets={setTotalAssets}
        noofsuppliers={noofsuppliers}
        recentPurchase={recentPurchase}
        rowData={rowData}
      />
    </div>
  )
}

export { OverviewWrapperInventory }

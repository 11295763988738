/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { Directus } from '@directus/sdk';
import { API_URL } from '../../../../_metronic/helpers/components/constant';
// import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useLocation } from "react-router";
import { Link } from 'react-router-dom'
import { requestPassword, checkUser, generate2FA, enableTfa, login } from '../redux/AuthCRUD'
// import TwoFactorDialog from './Twofactor';
import QRCode from "react-qr-code";


const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  otp: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(6, 'Maximum 6 characters')
  // .required('otp is required'),
})
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
})

const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(6, 'Maximum 6 characters')
    .required('Otp is required'),
})

const initialValues = {
  email: '',
  password: '',
  otp: ''
}


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [otploading, setOtpLoading] = useState(false)
  const [userType, setUserType] = useState('PARTNER')
  const dispatch = useDispatch()
  const directus = new Directus(API_URL);
  const location = useLocation();
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    isTFAEnable: null,
    otpauth_url: '',
    tfa_secret: '',
    otp: '',
    password: '',
    email: '',
    access_token: ''
  });
  const [showtwoFactor, setshowtwoFactor] = useState(false);
  const [page, setPage] = useState('');
  const [errStatus, setErrStatus] = useState(null);

  useEffect(() => {
    if (location) {
      let currentpage = location.pathname.split("/")
      setPage(currentpage[1])
    }
  }, [location]);

  useEffect(() => {
    if (location && location.pathname === '/auth/login') {
      dispatch(auth.actions.logout());
      setUserType('SUPERADMIN');
    }

  }, [location])

  useEffect(() => {
    if (loginDetails && loginDetails.tfa_secret) {
      setshowtwoFactor(true)
    }

  }, [loginDetails])

  useEffect(() => {
    if (errStatus) {
      setshowtwoFactor(false)
    }

  }, [errStatus])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setErrStatus(null)
      try {
        checkUser(values.email)
          .then(async (result: any) => {
            let temp: any = values
            if (!result?.data?.data) {
              setStatus('The login detail is incorrect')
              setLoading(false);
              return;
            }
            if (result?.data?.data?.tfa_secret && !values.otp) {
              temp.tfa_secret = result?.data?.data.tfa_secret;
              temp.isTFAEnable = true
              setLoginDetails(temp)
              // setEnableOtp(true);
              return
            } else {
              const response = await directus.auth.login({
                email: values.email,
                password: values.password
              });
              if (response.access_token) {
                // setLoading(false)
                temp.access_token = response.access_token;
                await generate2FA(values.password, response.access_token)
                  .then(async (result: any) => {
                    temp.otpauth_url = result.data.data.otpauth_url;
                    temp.tfa_secret = result.data.data.secret;
                    temp.isTFAEnable = false;
                    setLoginDetails(temp)
                  }).catch((errr) => {
                  })
                localStorage.setItem("userType", userType)
                // dispatch(auth.actions.login(response.access_token))
              } else {
                setLoading(false)
                localStorage.setItem("userType", '')
                setStatus('The login detail is incorrect')
              }
            }
          })
          .catch(() => {
            setStatus('The login detail is incorrect')
            setLoading(false)
          })
      } catch (err) {
        setLoading(false)
        localStorage.setItem("userType", '')
        setStatus('The login detail is incorrect')

        throw err;

      }


      // login(values.email, values.password)
      //   .then(({ data: { data } }) => {
      //     setLoading(false)
      //     directus.auth.login({
      //       email: values.email,
      //       password: values.password
      //     });
      //     localStorage.setItem("userType", userType)
      //     dispatch(auth.actions.login(data.access_token))
      //   })
      //   .catch(() => {
      //     setLoading(false)
      //     setSubmitting(false)
      //     setStatus('The login detail is incorrect')
      //   })
      // }, 1000)
    },
  })

  const formikreset = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        setTimeout(() => {
          localStorage.setItem("email", values.email)
          requestPassword(values.email)
            .then(({ data }) => {
              setStatus("We've sent you a secure link to reset your password")
              setLoading(false)
            })
            .catch(() => {
              setLoading(false)
              setSubmitting(false)
              setStatus("Failed to sent you a secure link to reset your password")
            })
        }, 1000)

      } catch (err) {
        setLoading(false)
        setStatus("Failed to sent you a secure link to reset your password")

        throw err;

      }

      // setTimeout(() => {
      //   resetPassword(values.email)
      //     .then(({ data: { result } }) => {
      //       console.log("result",result)
      //       setLoading(false)
      //     })
      //     .catch(() => {
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })


  const formikotp = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: otpSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setOtpLoading(true)
      try {
        if (!loginDetails.isTFAEnable && loginDetails.tfa_secret && values.otp) {
          await enableTfa(loginDetails.tfa_secret, values.otp, loginDetails.access_token)
            .then(async (result) => {
              const response = await directus.auth.login({
                email: loginDetails.email,
                password: loginDetails.password,
                otp: values.otp
              });
              if (response?.access_token) {
                // localStorage.setItem("userType", userType)
                dispatch(auth.actions.login(response.access_token))
              }
            }).catch(() => {
              setOtpLoading(false)
              setSubmitting(false)
              setStatus('Please try other Otp to verify')
            })
        }

        if (loginDetails.isTFAEnable && loginDetails.tfa_secret && values.otp) {
          const response = await directus.auth.login({
            email: loginDetails.email,
            password: loginDetails.password,
            otp: values.otp
          });
          if (response?.access_token) {
            // localStorage.setItem("userType", userType)
            dispatch(auth.actions.login(response.access_token))
          }
        }
      } catch (err) {
        setOtpLoading(false)
        setStatus('Please try other Otp to verify')

        throw err;

      }

      // setTimeout(() => {
      //   resetPassword(values.email)
      //     .then(({ data: { result } }) => {
      //       console.log("result",result)
      //       setLoading(false)
      //     })
      //     .catch(() => {
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })

  const reset = () => {
    setIsForgetPassword(false);
    setLoading(false)
    setLoginDetails({
      isTFAEnable: null,
      otpauth_url: '',
      tfa_secret: '',
      otp: '',
      password: '',
      email: '',
      access_token: ''
    });
    setshowtwoFactor(false);
    setOtpLoading(false);
    formikotp.resetForm();
  }

  return (
    <>
      {/* <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} /> */}
      <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{ background: '#456A76' }}>
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <a className="flex-column-auto mt-5 pb-lg-0 pb-10" href="#">
              <img alt="Logo" className="max-h-70px" src="../media/logos/logo-white.png" />
            </a>
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-white">Asset Management System</h3>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/* begin::Heading */}
              {
                !showtwoFactor && <div className='text-center mb-10'>
                  <h1 className='text-dark mb-3'>{isForgetPassword ? 'Reset password' : 'Sign In to AMS'}</h1>
                </div>
              }
              {
                showtwoFactor && <div className='text-center mb-10'>
                  <h1 className='text-dark mb-3'>{loginDetails?.tfa_secret ?
                    'Scan the code in your authenticator app to finish setting up 2FA' :
                    'Type the code from authenticator app to finish setting up 2FA'}</h1>
                </div>
              }


              {/* begin::Heading */}
              {!showtwoFactor && !isForgetPassword &&
                <form
                  className='form w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {formik.status || errStatus ? (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                  ) : null}

                  {/* begin::Form group */}
                  <div className='fv-row mb-10'>
                    {/* <label className='form-label fs-6 fw-bolder text-dark'>Email</label> */}
                    <input
                      placeholder='Email'
                      // onChange={(e)=>setEmail(e)}
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}

                  {/* begin::Form group */}
                  <div className='fv-row mb-10'>
                    <div className='d-flex justify-content-between mt-n5'>
                      <div className='d-flex flex-stack mb-2'>
                      </div>
                    </div>
                    <input
                      type='password'
                      placeholder='password'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                      <Link
                        to='#'
                        onClick={() => setIsForgetPassword(true)}
                        className='link-primary fs-6 fw-bolder'
                        style={{ marginLeft: '5px' }}
                      >
                        Forgot Password ?
                      </Link>
                    </div>
                  </div>
                  {/* end::Form group */}

                  {/* begin::Action */}
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <a className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot" href="#"></a>
                    <button id="kt_login_signin_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span>
                      {!loading && <span className='indicator-label'>{isForgetPassword ? 'Reset' : 'Sign In'}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}</span>
                    </button>
                  </div>
                </form>
              }
              {!showtwoFactor && isForgetPassword &&
                <form
                  className='form w-100'
                  onSubmit={formikreset.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {formikreset.status ? (
                    <div className='mb-lg-15 alert alert-success'>
                      <div className='alert-text font-weight-bold'>{formikreset.status}</div>
                    </div>
                  ) : null}

                  {/* begin::Form group */}
                  <div className='fv-row mb-10'>
                    {/* <label className='form-label fs-6 fw-bolder text-dark'>Email</label> */}
                    <input
                      placeholder='Email'
                      {...formikreset.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formikreset.touched.email && formikreset.errors.email },
                        {
                          'is-valid': formikreset.touched.email && !formikreset.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formikreset.touched.email && formikreset.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formikreset.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}
                  {/* begin::Action */}
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    {/* {
                      isForgetPassword &&
                      <button onClick={() => setIsForgetPassword(false)} id="kt_login_signin_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span>
                        {!loading && <span className='indicator-label'>{'Sign In'}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}</span>
                      </button>
                    } */}
                    <Link to="#" onClick={() => setIsForgetPassword(false)}>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-lg btn-light-primary fw-bolder'
                      // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Signin
                    </button>
                    </Link>{' '}
                    <button id="kt_login_signin_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span>
                      {!loading && <span className='indicator-label'>{isForgetPassword ? 'Reset' : 'Sign In'}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}</span>
                    </button>

                  </div>
                </form>
              }
              {
                showtwoFactor && <form
                  className='form w-100'
                  onSubmit={formikotp.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {formikotp.status ? (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{formikotp.status}</div>
                    </div>
                  ) : null}
                  {
                    loginDetails && !loginDetails?.isTFAEnable &&
                    <div className="form-group row">
                      <div className='col-lg-12'>
                        <div className="qrcode">
                          <QRCode
                            size={356}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={loginDetails && loginDetails?.otpauth_url}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      Please install Google Authenticator app from play store or ios store in mobile. <br /> <a style={{ fontSize: '14px' }} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share">Android</a>,
                      <a style={{ fontSize: '14px' }} href="https://apps.apple.com/us/app/google-authenticator/id388497605">IOS</a>.
                    <br />
                      - Once installed Scan above QR code in App.
                      <br />
                      - Enter the OTP into below field.
                    </div>
                    </div>
                  }
                  {/* begin::Form group */}
                  <div className='fv-row mb-10'>
                    {/* <label className='form-label fs-6 fw-bolder text-dark'>otp</label> */}
                    <input
                      placeholder='otp'
                      {...formikotp.getFieldProps('otp')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formikotp.touched.otp && formikotp.errors.otp },
                        {
                          'is-valid': formikotp.touched.otp && !formikotp.errors.otp,
                        }
                      )}
                      type='text'
                      name='otp'
                      autoComplete='off'
                    />
                    {formikotp.touched.otp && formikotp.errors.otp && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formikotp.errors.otp}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}


                  {/* begin::Action */}
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Link to="#" onClick={() => {
                      reset()
                    }}>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-lg btn-light-primary fw-bolder'
                      // disabled={formik.isSubmitting || !formik.isValid}
                      >
                        Back
                        </button>
                    </Link>{' '}
                    <button id="kt_login_signin_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span>
                      {!otploading && <span className='indicator-label'>{'Verify OTP '}</span>}
                      {otploading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}</span>
                    </button>

                  </div>
                </form>
              }
              {/*  */}
            </div>
          </div>
        </div>
      </div>
      {/* {
        <TwoFactorDialog
          showDialog={showtwoFactor}
          loginDetails={loginDetails}
          directus={directus}
          dispatch={dispatch}
          auth={auth}
          enableTfa={enableTfa}
          setErrStatus={setErrStatus}
          setshowtwoFactor={setshowtwoFactor}
          setpageLoading={setLoading}
          login={login}
          setLoginDetails={setLoginDetails}
        />
      } */}
    </>
  )
}


import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Step1 from './inventory-step1';
import Step3 from './inventory-step2';
import Check from '@material-ui/icons/Check';
import { PageTitle } from '../../../../_metronic/layout/core'
import moment from "moment";
import { CreateInventory, UpdateInventory, getassetsList } from '../redux/AssetsCrud';
import { cleanEmptyObj } from '../../../../_metronic/helpers/components/utils'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import CustomCancelButton from "../../../../_metronic/helpers/uikit/CancelButton";
import CircularProgress from '@mui/material/CircularProgress'
import { PURCHASEDASSETS } from "../../../../_metronic/helpers/components/constant"
import { directUSAPICall, groupByAgreement } from "../../../../_metronic/helpers/components/utils"
import { SVRemovePercentageformatter, SVformatter } from '../../../../_metronic/helpers/components/utils'

import { useSelector } from "react-redux";
import FilterSelection from '../../../../_metronic/helpers/components/Filter';
import { getSupplierList } from '../../supplier/redux/SupplierCrud';


const useStyles = makeStyles(theme => ({
  labelContainer: {
    "& $alternativeLabel": {
      fontSize: '20px'
    }
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Asset Info', 'User Info'];
}

export default function AssetCreateForm({
  searchAssetId,
  setShowAssetCreate,
  handleAssetsList,
}) {
  const classes = useStyles();
  const history = useHistory()
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const urlParams = useParams()
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState("")
  const [assetTypes, setAssetTypes] = useState([]);
  const [values, setValues] = useState({
    id: '',
    cost_centre: null,
    user_invoice_address: null,
    user_country: null,
    user_division: null,
    user_city: null,
    user_address: null,
    email_phoneno: null,
    user_email: null,
    user_name: null,
    status: "published",
    sum_purchase_value: "",
    price_total: null,
    quantity: null,
    client: null,
  });

  const steps = getSteps();
  const { data } = useSelector(({ auth }) => auth.user);
  const [userInfo, setUserInfo] = useState(data);
  const [asset_id, setAsset_id] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [isUserDisable, setUserIsDisable] = useState(false);
  const [showFields, setShowFields] = useState(false)
  const [suppliers, setSuppliers] = useState([])

  function totalSteps() {
    return getSteps().length;
  }

  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (searchAssetId) {
      setLoading(true);
      getAssetDetailData(searchAssetId)
      setAsset_id(searchAssetId)
    }

  }, [searchAssetId])

  useEffect(() => {
    // setIsloading(true)
    // partnersData()

    suppliersData();
    getassetTypes();
  }, [urlParams])



  const getAssetDetailData = async (id) => {

    setLoading(false)
    let fields = `${PURCHASEDASSETS}`
    const result = await directUSAPICall().items('purchased_assets').readOne(id, { fields: [fields] });
    if (result && result.pid) {
      setLoading(false)
      if (result.supplier) {
        result.supplier = result.supplier.id
      }
      if (result.client) {
        result.client = result.client.id
      }
      setValues(result)
    } else {
      setLoading(false)
    }
  }

  const getassetTypes = async () => {

    let options = {
      limit: -1,
      sort: 'asset_name',
      fields: ['asset_name,fields']
    }
    const result = await directUSAPICall().items('asset_type').readMany(options);
    if (result.data && result.data.length > 0) {
      // console.log("result",result.data)
      let assetNames = result.data.map(
        (assetType) => assetType.asset_name.toUpperCase()
      );
      setAssetTypes(assetNames);

    } else {
      setAssetTypes([])
    }
  }



  const suppliersData = () => {
    let queryParam = "?sort=supplier_name&filter[supplier_org_no][_nnull]=true&fields=supplier_org_no,supplier_name,id"
    getSupplierList(queryParam)
      .then(({ data: { data } }) => {
        setSuppliers(data)
      })
      .catch(() => {
      })
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1
          suppliers={suppliers}
          values={values}
          isDisable={isDisable}
          setValues={setValues}
          assetTypes={assetTypes}
          urlParams={urlParams}
          showFields={showFields}
          isDisable={isDisable}
          userInfo={userInfo}
        />;
      case 1:
        return <Step3
          values={values}
          isDisable={isDisable}
          setValues={setValues}
          urlParams={urlParams}
          showFields={showFields}
          isDisable={isDisable}
        />;
      default:
        return 'Unknown step';
    }
  }

  function isStepOptional(step) {
    return step === 1;
  }


  function skippedSteps() {
    return skipped.size;
  }

  function completedSteps() {
    return completed.size;
  }

  function allStepsCompleted() {
    return completedSteps() === totalSteps() - skippedSteps();
  }

  function isLastStep() {
    return activeStep === totalSteps() - 1;
  }

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleStep = step => () => {
    setActiveStep(step);
  };

  function handleReset() {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function isStepComplete(step) {
    return completed.has(step);
  }

  useEffect(() => {
    if (data && data?.id && data.role) {

      setUserInfo(data)
      // lists()
    }
  }, [data])


  const handleSubmit = (type) => {
    let des = ''
    if (description) {
      // let notsss = []
      des = {
        description: description,
        status: 'published'
      }
      // values.notes = des
    }
    values.client = data.client?.id || values.client;
    delete values.user_updated;
    delete values.user_created;
    delete values.date_created;
    delete values.date_updated;
    values.price_total = values.price_total ? SVRemovePercentageformatter(values.price_total) : null;

    setLoading(true)
    // values.date_of_delievery_approval = values.date_of_delievery_approval ? moment(values.date_of_delievery_approval).format('DD/MM/YYYY') : null
    if (!values.pid || values.pid === 0 && cleanEmptyObj(values)) {

      CreateInventory(cleanEmptyObj(values))
        .then(({ data: { data } }) => {
          // console.log("cleanEmptyObj(values)", cleanEmptyObj(values))
          // if(type === 'saveexit') {
          setLoading(false)

          // console.log("idddddd", data.aggrement)

          if (type === 'savenext') {
            // history.push(`/createagreement/${data.id}`)
            values.pid = data.pid
            setValues({ ...values });
            handleNext()
          }
          if (type === 'saveexit') {
            values.pid = data.pid

            setValues({ ...values });
            handleAssetsList()
            history.push('/inventory')


          }

          // setAgreementList(data)
          // setIsloading(false)
          //   dispatch(auth.actions.login(accessToken))
        })
        .catch(() => {
          // setIsloading(false)
          //   setStatus('The login detail is incorrect')
        })
    }

    if (values.pid) {
      UpdateInventory(values, values.pid)
        .then(({ data: { data } }) => {
          // if(type === 'saveexit') {
          setLoading(false)
          if (type === 'savenext') {
            values.pid = data.pid;
            setValues({ ...values });
            handleNext()
          }
          if (type === 'saveexit') {
            values.pid = data.pid
            setValues({ ...values });
            console.log("values", values)
            setShowAssetCreate(false);
            handleAssetsList()
            history.push('/inventory')
            // history.push('/assets')
          }
          // setAgreementList(data)
          // setIsloading(false)
          //   dispatch(auth.actions.login(accessToken))
        })
        .catch(() => {
          // setIsloading(false)
          //   setStatus('The login detail is incorrect')
        })
    }

  }


  return (
    <>
      {/* <div>
        <FilterSelection
          title={`${values.id ? 'Modify' : ''} Asset`}
          redirect="assets"
        />
      </div> */}
      <div className="card card-custom gutter-b bg-smoke">
        <div className='card-body'>
          <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const buttonProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      icon={<Check className={[classes.xiconRoot, index === activeStep ? 'activestep' : ''].join(' ')} />}
                      onClick={handleStep(index)}
                      completed={isStepComplete(index)}
                      {...buttonProps}
                    >
                      <StepLabel
                        classes={{
                          iconContainer: classes.iconContainer
                        }}
                      >
                        {label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
            </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                  <div className="stepper-btn">
                    {
                      !isDisable ?
                        <CustomCancelButton
                          variant="secondary"
                          color="danger"
                          to='inventory'
                        /> : null}

                    <Button variant="contained"
                      color="warning" disabled={activeStep === 0} onClick={handleBack} className={[classes.button, 'backbtn']}>
                      Back
              </Button>
                    {
                      !isDisable || !isUserDisable ? <>

                        {activeStep < 4 && <Button
                          onClick={() => handleSubmit('savenext')}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                          <span className='indicator-label'>{'Save & Next'}</span>
                          {
                            loading && <CircularProgress />
                          }

                        </Button>}

                        <Button
                          onClick={() => handleSubmit('saveexit')}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                          <span className='indicator-label'>{'Save & Exit'}</span>
                          {
                            loading && <CircularProgress />
                          }

                        </Button></> : <Button
                          onClick={() => handleNext()}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                        <span className='indicator-label'>{'Next'}</span>
                      </Button>
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

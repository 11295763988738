import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "@material-ui/core";

import { Link, useHistory, useLocation } from 'react-router-dom'
// import FileUpload from './components/FileUpload';
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import _ from "lodash";

import { Select as MatSelect } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FileUpload from "../../../../app/modules/assets/components/FileUpload"
import BulkUpdateDialog from './components/BulkUpdateDialog';
import NotesDialog from './components/NotesDialog'
import { directUSAPICall, SVPercentageformatter, SVformatter, getMonthDiff, generateExcel, FormatFilterValues } from "../utils"
import { getAgreementStatus } from "../calculation"
import { ASSETAGGREMENTFIELDS, ASSETSTATUS, PRODUCTCATEGORIES, FILTER_AG_STATUS } from "../constant";
import DeleteConfirmDialog from "../../components/deleteConfirm"
import { ClientAddessList } from '../../../../app/modules/client/redux/ClientCrud'
import { useStyles } from "./components/utils";
import { useParams } from 'react-router-dom';
// import PageLoader from "../PageLoader";
import AssetViewDialog from '../../../../app/modules/assets/components/AssetViewDialog'
import { UpdateFilter, getFilters, CreateFilter, UpdatePresets, CreatePresets } from '../../../../app/modules/assets/redux/AssetsCrud'
// import Pagination from "./components/paging";
import CustomDropdown from "./components/CustomDropdown";
import DropDownSelect from './components/SelectDropDown'
import Drawer from '../../../../app/modules/components/sidedrawer';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ExportModal from './components/ExportData';


export default function AssetForm(props) {
  const { title,
    createTitle,
    createPath,
    searchString,
    page,
    searchcolumns,
    setOpen
  } = props;
  const classes = useStyles();
  const history = useHistory()
  const urlParams = useParams();
  const overlayLoadingTemplate =
    '<span className="ag-overlay-loading-center">Loading, Please Wait...</span>';
  const noRowsTemplate =
    '<span className="ag-overlay-loading-center">No records found...</span>';
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [assetsListData, setAssetsListData] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [quickFilterText, setQuickFilterText] = useState('');
  const [agreementsInfoList, setAgreementsInfoList] = useState()
  const [rowDataAPI, setRowDataAPI] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [enableBulkUpdates, setEnableBulkUpdates] = useState(false);
  const [selectedAssetType, setSelectedAssetType] = useState('All');
  const externalFilterRef = useRef(null);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetTypeFieldMapping, setAssetTypeFieldMapping] = useState([]);
  const [bulkUpdateOpen, setBulkUpdateOpen] = useState(false);
  const [bulkupdatedData, setBulkupdatedData] = useState([]);
  const [tablecolumns, setTableColumns] = useState([])
  const [selectedAsset, setSelectedAsset] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [statusNames, setStatusNames] = useState()
  const { data } = useSelector(({ auth }) => auth.user);
  const [userInfo, setUserInfo] = useState(data);
  const [partnersValues, setPartnersValues] = React.useState([]);
  const [clientsValues, setClientsValues] = React.useState([]);
  const [values, setValues] = useState({
    partner: null,
    client: null,
    aggrement: null
  })
  const [agreementList, setAgreementList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [loggedUserRole, setLoggedUserRole] = useState();
  const [isSelect, setIsSelect] = useState(true)
  const [userPermission, setUserPermission] = useState({
    canEdit: true,
    canDelete: true,
    canView: true,
    canAdd: true,
    canImport: true,
    canBulkDelete: true,
    canImport: true,
    canBulkUpdate: true,
    canNotes: true
  });
  const [hideColumns, setHideColumns] = useState(false);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [presetId, setPresetId] = React.useState()
  const [showFields, setShowFields] = useState(false)
  const [showAssetCreate, setShowAssetCreate] = useState(false);
  const [totalRowDatasFilterCount, setTotalRowDatasFilterCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [EOLACTION, setEOLACTION] = useState('')
  const [userTypes, setUserTypes] = useState({
    isClient: false,
    isAdmin: false,
    isPartner: false
  });
  const [agreementListTemp, setAgreementListTemp] = useState([])
  const [rowDataTemp, setRowDataTemp] = useState([]);
  const [tempColumnDefs, setTempColumnDefs] = useState([]);
  const [tempColumns, setTempColumns] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [colValues, setColValues] = useState([]);
  const [tempFieldsList, setTempFieldsList] = useState([]);
  const [totalAssetValue, setTotalAssetValue] = useState('');
  const [dataSourceparams, setDataSourceparams] = useState(null);
  const [globalSearchValues, setglobalSearchValues] = useState(null);
  const gridRef = useRef();
  const [showExport, setShowExport] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isSelectAll, setisSelectAll] = useState(false);

  // useEffect(() => {
  //   // localStorage.setItem("filterValues", "")
  // }, []);

  useEffect(() => {
    if (userInfo) {
      if (userInfo && userInfo.role?.name === 'admin_user' || userInfo.role?.name === 'master_admin') {
        setShowFields(true)
        setUserTypes({
          isClient: false,
          isAdmin: true,
          isPartner: false
        })
      }
    }
  }, [userInfo])

  // const getAgValuess = async (partner, client, agreementList_Temp, totalAssets_List, selectedAgIds) => {
  //   let values = await getAgValuesFormat(partner, client, agreementList_Temp, totalAssets_List, selectedAgIds, userTypes, 'aspage')
  //   setRowData(values.assetsList)
  // }

  function onClick(type) {
    setIsSelect(type)
    let temp = tempColumns
    temp.forEach((item) => {
      item.checked = type
    })
    setTempColumns(temp)
  }
  const handleCopy = () => {
    if (gridApi) {
      gridApi.paginationGoToFirstPage();
    }
    let selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length === 0) {
      return;
    }
    let updatedRows = JSON.parse(JSON.stringify(selectedRows.slice()));
    updatedRows.map((row) => {
      delete row['id'];
    });
    setRowData((prev) => [...updatedRows, ...prev]);
    setTimeout(() => {
      gridApi.startEditingCell({
        rowIndex: 0,
        colKey: 'no_of_quantity',
        keyPress: '1',
      });
    }, 500);
  };

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    if (localStorage.getItem("selectedAssetType")) {
      setSelectedAssetType((localStorage.getItem("selectedAssetType")))
    }
    if (localStorage.getItem("globalsearchField")) {
      setglobalSearchValues((localStorage.getItem("globalsearchField")))
    }
    localStorage.setItem("filterValues", "") //reset filter values
    if (gridApi.columnController) {
      let allColumnIds = gridApi.columnController.gridColumns.map(
        (col) => col.colId
      );
      let currentMapping = [];
      if (assetTypeFieldMapping && assetTypeFieldMapping.length > 0) {
        currentMapping = assetTypeFieldMapping.find(
          (mapping) => mapping.asset_name.toLowerCase() === selectedAssetType.toLowerCase()
        );

      }

      let columnsToShow = currentMapping ? currentMapping.fields : [];

      if (!columnsToShow || columnsToShow.length === 0) {
        gridApi.columnController.setColumnsVisible(allColumnIds, true);
        return;
      }

      if (columnsToShow.length > 0) {
        columnsToShow.push('actions', 'id');
      }
      // columnDefs.push({ field: 'SOME RANDOM', headerName: 'SOME RANDOM' });
      gridApi.columnController.setColumnsVisible(allColumnIds, false);
      gridApi.columnController.setColumnsVisible(columnsToShow, true);
    }

  }, [gridApi, assetTypeFieldMapping, selectedAssetType]);

  const externalFilterChanged = (value) => {
    setSelectedAssetType(value);
  };

  useEffect(() => {
    // setIsloading(true)
    // partnersData()

    if (userInfo && userInfo?.id) {
      if (userInfo.role) {
        if ((userInfo.role.name !== "client_admin") || (userInfo.role.name !== "client_user")) {

          // listsAllAgreement()
        }
        if (userInfo.role.name === 'client_admin' || userInfo.role.name === 'client_user') {
          setUserTypes({
            isClient: true,
            isAdmin: false,
            isPartner: false
          })
          values.client = userInfo.client.id;
          getClientList(null, userInfo?.client?.id)
        }
        else if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
          setUserTypes({
            isClient: false,
            isAdmin: false,
            isPartner: true
          })
          values.partner = userInfo?.partner?.id
          getClientList(userInfo?.partner?.id, null)
        } else {
          getClientList(null, null)
        }
      }
    }
  }, [userInfo])

  const lists = async (gridApi, eol_action = null, displayFields = [], request = dataSourceparams, isClear = null, exportlimit = null) => {
    if (!exportlimit) {
      setLoading(true);
      gridApi && gridApi.showLoadingOverlay();
    }
    let tempValues = localStorage.getItem("filterValues") ? JSON.parse(localStorage.getItem("filterValues")) : values;
    let fields = ''
    if (page === 'searchmodal') {
      fields = `${ASSETAGGREMENTFIELDS},id,aggrement.EOL_value_amt,price_total,rent_period,serial_number,user_name,user_email,user_phoneno,cost_centre,product_group,insurance_company,Interim_rent_cost,user_created.first_name,user_created.last_name,EOL_action`
      // fields = `${searchString && 'search='+searchString+'&aggrement.id,aggrement.agrement_info_no,aggrement.no_of_month_lease,aggrement.founding_partner.supplier_name,aggrement.eol_date,aggrement.lease_start_date,price_total,rent_period,serial_number,product_group,insurance_company,Interim_rent_cost'}`
    } else {
      // let cols = tablecolumns.map(
      //   (status) => status.field
      // );
      if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
        fields = `${displayFields.toString()},${ASSETAGGREMENTFIELDS},aggrement.partnersalesperson,notes,id,rent_period,price_total,aggrement.billing_period,import_id,aggrement.client.id,aggrement.client.name,aggrement.client.clients_org_no,aggrement.partner.id,aggrement.partner.partner_name,aggrement.partner.partner_org_no,order_number`
      } else if (userInfo?.userType === "PARTNER" || userInfo?.userType === "CLIENT") {
        fields = `${displayFields?.length > 0 ? displayFields.toString() + ',' : ''}${ASSETAGGREMENTFIELDS},id,rent_period,price_total,aggrement.billing_period,import_id,aggrement.client.id,aggrement.client.name,aggrement.client.clients_org_no,aggrement.partner.id,aggrement.partner.partner_name,aggrement.partner.partner_org_no`
      }
    }
    setRowData([]);

    let sort = '-id';
    if (request?.sortModel?.length > 0) {
      sort = `${request?.sortModel[0].sort === 'asc' ? '' : '-'}${request?.sortModel[0].colId}`
    }
    const currentpage = request ? request.endRow / rowsPerPage : 1;

    let options = {
      limit: exportlimit ? exportlimit : rowsPerPage,
      sort: sort,
      fields: [fields],
      page: currentpage,
      meta: ['total_count', 'filter_count'],
      filter: {
        _and: [
          {
            "aggrement": {
              "status": {
                _nnull: true
              }
            }
          }
        ]
      }
    }
    let options1 = {
      limit: -1,
      aggregate: {
        sum: ['price_total']
      },
      filter: {
        _and: [{
          "aggrement": {
            "status": {
              _nnull: true
            }
          }
        }]
      }
    }
    // console.log("values",values)

    if (userInfo.role) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        if (selected && selected.length > 0) {
          tempValues.client = userInfo.client.id
        } else {
          options.filter['_and'].push({
            "aggrement": {
              "client": {
                "id": {
                  "_eq": userInfo.client.id
                }
              }
            }
          })
        }
      } else if (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') {
        // console.log("users_details", users_details)

        if (selected && selected.length > 0) {
          tempValues.partner = userInfo.partner.id;
        } else {
          // options.filter['_and'].push({
          //   "aggrement": {
          //     "partner": {
          //       _eq: userInfo.partner.id
          //     }
          //   }
          // })
        }

      }

      if (tempValues.client && !tempValues.partner && selected.length === 0) {
        options.filter = {
          _and:
            [{
              "aggrement": {
                "client": {
                  "id": {
                    "_eq": tempValues.client
                  }
                }
              }
            }]
        }
      }
      if (tempValues.partner && !tempValues.client && selected.length === 0) {
        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "id": {
                "_eq": tempValues.partner
              }
            }
          }
        })
      }
      if (tempValues.partner && tempValues.client && selected.length === 0) {
        options.filter = {
          _and:
            [
              {
                "aggrement": {
                  "partner": {
                    "id": {
                      "_eq": tempValues.partner
                    }
                  }
                }
              },
              {
                "aggrement": {
                  "client": {
                    "id": {
                      "_eq": tempValues.client
                    }
                  }
                }
              }
            ]
        }

      }
      // console.log("values", options.filter)
      // return
      if (selected && selected.length > 0) {
        let tempselected = selected.map(
          (item) => item.id
        );
        values.aggrement = tempselected;
        options.filter['_and'].push({
          "aggrement": {
            "partner": {
              "id": {
                "_eq": tempValues.partner
              }
            }
          }
        },
          {
            "aggrement": {
              "client": {
                "id": {
                  "_eq": tempValues.client
                }
              }
            }
          },
          {
            "aggrement.id": {
              _in: tempselected
            }
          })

      }
      if (urlParams && urlParams.id) {
        // options.filter = {
        //   "aggrement.id": {
        //     _in: urlParams.id
        //   }
        // }
        options.filter['_and'].push({
          "aggrement": {
            "id": {
              _in: urlParams.id
            }
          }
        })
      }
      if (urlParams && urlParams.type) {
        options.filter['_and'].push({
          "product_group": {
            _contains: urlParams.type
          }
        })
      }
      if (!urlParams?.id && (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") && !exportlimit) {
        options.filter['_and'].push({
          "aggrement": {
            "ag_status": {
              _in: FILTER_AG_STATUS
            }
          }
        })
      }
    }

    if (searchString) {
      options.search = `${searchString}`
    }
    if (eol_action !== 'all' && (eol_action || EOLACTION)) {
      options.filter['_and'].push({
        "EOL_action": {
          _eq: eol_action || EOLACTION
        }
      })
    }
    if (isClear === 'clear') {
      request.filterModel = {}
    }
    // console.log(isClear,"request.filterModel",request.filterModel)

    if (request && Object.keys(request.filterModel).length > 0) {
      const filterVal = FormatFilterValues(request.filterModel);
      let filterParam = options.filter['_and'] ? [...options.filter['_and'], ...filterVal] : [...filterVal];
      options.filter['_and'] = filterParam
      options1.filter['_and'] = filterParam
    } else {
      if (options.filter['_and']?.length > 0) {
        options.filter['_and'] = [...options.filter['_and']]
        options1.filter['_and'] = [...options.filter['_and']]
      }
    }
    if (globalSearchValues) {
      let string = globalSearchValues.split(" ");
      let filters = {
        _and: []
      }
      string.map((obj) => {
        filters['_and'].push({
          "searchfield": {
            _contains: `-${obj.toLowerCase()}`
          }
        })
      })
      options.filter['_and'] = options.filter['_and'] ? [...options.filter['_and'], ...filters['_and']] : [...filters['_and']]
      options1.filter['_and'] = options.filter['_and'] ? [...options.filter['_and'], ...filters['_and']] : [...filters['_and']]
      // localStorage.setItem("globalsearchField", values.searchfield)
      // options.filter['_and'].push(filters)
    }

    if (localStorage.getItem("selectedAssetType")) {
      setSelectedAssetType((localStorage.getItem("selectedAssetType")))
    }
    // console.log("valuesssss", values)

    await directUSAPICall().items('Asset').readMany(options).then(async (result) => {
      if (!exportlimit) {
        const result1 = await directUSAPICall().items('Asset').readMany(options1);
        setTotalRowDatasFilterCount(result.meta.filter_count)
        setTotalAssetValue(result1.data[0].sum.price_total)
      }
      let response = result.data;
      if (result.data && result.data.length > 0) {
        if (!exportlimit) {
          setLoading(false)
          let statusNames = ASSETSTATUS.map(
            (status) => status.value
          );
          setStatusNames(statusNames);
          setRowData(response)
          setRowDataTemp(response)
          let tempAPI = JSON.parse(JSON.stringify(response));
          setRowDataAPI(tempAPI);
          request.successCallback(response, result.meta.filter_count);
          // setValues(JSON.parse(localStorage.getItem("filterValues")))

          gridApi && gridApi.hideOverlay();
        } else {
          setIsDownloaded(true)
          // setShowExport(false)
          exportExcelData(response)
        }

      } else {
        setLoading(false);
        // setPageLoading(false)
        setRowData([])
        setRowDataTemp(response)
        let tempAPI = JSON.parse(JSON.stringify(response));
        setRowDataAPI(tempAPI);
        request.successCallback(response, result.meta.filter_count);
        gridApi && gridApi.hideOverlay();

      }
    }).catch((err) => {
      console.log("errrrrrr", err)

      gridApi && gridApi.hideOverlay();

    })


  }

  const getassetTypes = async () => {

    let options = {
      limit: -1,
      sort: '-asset_name',
      fields: ['asset_name,fields']
    }
    const result = await directUSAPICall().items('asset_type').readMany(options);
    if (result.data && result.data.length > 0) {
      // console.log("result",result.data)
      setAssetTypeFieldMapping(result.data);
      let assetNames = result.data.map(
        (assetType) => assetType.asset_name.toUpperCase()
      );
      setAssetTypes(assetNames);

    } else {
      setAssetTypes([])
    }
  }

  // useEffect(() => {
  //   // console.log("userDetail", userDetail)
  //   if (userInfo && userInfo.id && userInfo?.role?.name) {
  //     setTableColumns(columns)
  //     getassetTypes()
  //     lists(gridApi, page)
  //     // }
  //   }
  // }, [])

  useEffect(() => {
    if (userInfo && userInfo.id && userInfo?.role?.name) {
      setLoggedUserRole(userInfo.role.name)
    }
  }, [userInfo])

  const getColumnDefinitions = async (gridApi, page) => {
    let options = {
      limit: -1,
      sort: 'order_by_id',
      // fields: [fields]
    }
    if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
      options.filter = {
        _and:
          [
            {
              "adminview": {
                _eq: true
              }
            }
          ]
      }
    } else if (userInfo?.userType == "PARTNER") {
      options.filter = {
        _and:
          [
            {
              "partnerview": {
                _eq: true
              }
            }
          ]
      }
    } else if (userInfo?.userType == "CLIENT") {

      options.filter = {
        _and:
          [
            {
              "clientview": {
                _eq: true
              }
            }
          ]
      }
    }
    const results = await directUSAPICall().items('Asset_Column_Definitions').readMany(options).then((result) => {
      if (result) {
        // setColumnDefs(result.data)
        setTempColumnDefs(result.data)
      }
    }).catch((err) => {
    })
  }

  useEffect(() => {
    if (loggedUserRole) {
      if (loggedUserRole === 'partner_user' || loggedUserRole === 'partner_admin') {
        setUserPermission({
          canAdd: false,
          canBulkDelete: false,
          canImport: false,
          canBulkUpdate: false,
          canDelete: false,
          canEdit: true,
          canView: true,
          canNotes: false,
        })
      }
      if (loggedUserRole === 'client_user' || loggedUserRole === 'client_admin') {
        setHideColumns(true)
        let obj = {
          canAdd: false,
          canBulkDelete: false,
          canImport: false,
          canBulkUpdate: false,
          canDelete: false,
          canEdit: true,
          canView: true,
          canNotes: false,
        }
        setUserPermission(obj)

      }
    }
  }, [loggedUserRole])

  const gotoEdit = (values, type = null) => {
    if (page === 'searchmodal' || type === 'view' || type === 'edit') {
      setSelectedAssetId(values.id)
      setLoading(true)
      if (type === 'view') {
        setShowAssetDetail(true)
      } else if (type === 'edit') {
        setShowAssetCreate(true)
      }
    } else {
      history.push(`/updateasset/${values.id}`)
    }
  }

  const deleteConfirm = (values) => {
    setShowDeleteDialog(true)
    let arr = [];
    arr.push(values)
    setDeleteId(arr)
  }

  const handleBulkDelete = async (props) => {
    let selectedRows = gridApi.getSelectedRows();
    setDeleteId(selectedRows)
    setShowDeleteDialog(true)
  };

  const ActionRendererComponent = ({ data }) => {
    let props = data
    return (
      props ?
        <div>

          {
            (userPermission && userPermission.canView) && <i onClick={() => gotoEdit(props, 'view')} className="fas fa-eye" />
          }
          {
            (props && userPermission && userPermission.canUserEdit) && <i onClick={() => gotoEdit(props, 'edit')} className="fas fa-edit" />
          }
          {(!searchString && userPermission && userPermission.canEdit) && (
            <i onClick={() => gotoEdit(props, 'edit')} className="fas fa-edit" />

          )}
          {(searchString && userPermission && userPermission.canEdit) && (
            <i className="fas fa-edit" onClick={() => gotoEdit(props, 'edit')} />
          )}
          <i className={`${props?.notes ? 'fas' : 'far'} fa-sticky-note`} onClick={() => handleNotestOpen(props)}></i>

          {(userPermission && userPermission.canDelete) && <i className="fas fa-trash-alt deleteone" onClick={() => deleteConfirm(props)} />}
        </div> : null
    );
  }

  let TextFilterParams = {
    filterOptions: ['contains'],
    textFormatter: (r) => {
      if (r == null) return null;
      return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    maxNumConditions: 1,
  };

  let EqualFilterParams = {
    filterOptions: ['equals'],
    textFormatter: (r) => {
      if (r == null) return null;
      return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    maxNumConditions: 1,
  };

  const buildColumnDefinitions = (columnDefs) => {
    return columnDefs && columnDefs.map((columnDef, index) => {
      let columnDefinition = {
        headerName: (index !== 0) ? columnDef.header_name : '',
        cellRenderer: columnDef.field === 'actions' ? ActionRendererComponent : columnDef.field === "EOL_action" ? 'DropDownSelect' : false,
        // headerComponentFramework: ActionRendererComponent,
        // cellRendererParams: {
        //   onRowEditingStopped: (params) => onRowEditingStopped(params),
        // },
        // headerCheckboxSelection: index === 0 ? true : false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: index === 0 ? true : false,
        field: columnDef.field,
        editable: false,
        filter: index !== 0 ? columnDef.filter : false,
        sortable: true,
        resizable: false,
        // suppressAggFuncInHeader: true,
        // enableCellChangeFlash: true,
        filterParams: index !== 0 ? (columnDef.type === 'currencyColumn' || columnDef.type === 'number' || columnDef.type === 'date') ? EqualFilterParams : TextFilterParams : false,
        filterType: index !== 0 ? columnDef.type === 'currencyColumn' ? 'number' : columnDef.type : false,
        // hide: getMonthDiff(columnDef.field, params),
        // width: index !== 0 ? ((columnDef.field === 'model') ? 400 : (columnDef.field.length <= 9 ? 90 : columnDef.field.length >= 12 ? 150 : columnDef.field.length >= 17 ? 160 : 180)) : 180,
        width: 'auto',
        // cellClass: columnDef.type === 'currencyColumn' ? 'ag-right-aligned-cell' : '',
        valueFormatter: (params) => {
          // console.log("params", params.data.aggrement)
          if (columnDef.type === 'currencyColumn') {
            return params.value ? SVformatter(params.value) + ' kr' : '0 kr';
          }
          if (params.value && columnDef.type === 'date') {
            return moment(params.value).format('YYYY-MM-DD');
          }
          if (columnDef.type === 'percentage') {
            let val = params.value ? Math.round(params.value * 100) / 100 : '';;
            return val ? SVPercentageformatter(val) : '0.00%';
          }
        },
        valueGetter: (params) => {
          if (params.data) {
            params.data.agrement_info_no = (params.data?.aggrement?.agrement_info_no);
            params.data.our_agreement_ref = (params.data?.aggrement?.our_agreement_ref);
            params.data.lease_start_date = (params?.data?.aggrement?.lease_start_date);
            params.data.eol_date = (params?.data?.aggrement?.eol_date);
            params.data.ag_status = (params?.data?.aggrement?.ag_status);

            params.data.exit_options = (params?.data?.aggrement?.exit_options);
            params.data.invoicing_to = (params?.data?.aggrement?.invoicing_to);
            params.data.original_sign_date = (params?.data?.aggrement?.original_sign_date);
            params.data.type_of_leasing = (params?.data?.aggrement?.type_of_leasing);
            params.data.no_of_month_lease = (params?.data?.aggrement?.no_of_month_lease);
            params.data.montly_payment_ratio = (params?.data?.aggrement?.montly_payment_ratio);
            params.data.billing_period = (params?.data?.aggrement?.billing_period);

            if (params?.data?.aggrement?.client) {
              params.data.client_name = params.data.aggrement.client.name ? params.data.aggrement.client.name : ''
              params.data.clients_org_no = params.data.aggrement.client.clients_org_no ? params.data.aggrement.client.clients_org_no : ''
            }
            if (params.data?.aggrement?.supplier) {
              params.data.supplier_name = params.data.aggrement?.supplier?.supplier_name ? params.data.aggrement.supplier.supplier_name : ''
              params.data.supplier_org_no = params.data.aggrement?.supplier?.supplier_org_no ? params.data.aggrement.supplier.supplier_org_no : ''
            }
            if (params?.data?.aggrement?.partner) {
              params.data.partner_name = params.data.aggrement.partner.partner_name ? params.data.aggrement.partner.partner_name : ''
              params.data.partner_org_no = params.data.aggrement.partner.partner_org_no ? params.data.aggrement.partner.partner_org_no : ''
            }
            if (params?.data?.user_name) {
              params.data.user_name = capitalizeFirstLetter(params.data.user_name)
            }
            if (params?.data?.model) {
              params.data.model = capitalizeFirstLetter(params.data.model)
            }
            params.data.partner_sales_person = params?.data?.partner_sales_person ? params?.data?.partner_sales_person : (params?.data?.aggrement?.partnersalesperson || null);

            if (params.data.ag_status) {
              if (params?.data?.aggrement && params?.data?.aggrement?.ag_status && params?.data?.aggrement?.lease_start_date) {
                // let monthDiff = getMonthDiff(params.data.eol_date);
                // let ag_status = getAgreementStatus(params.data, monthDiff).status || ''
                let statusList = ["EXTENDED"]
                let monthDiff = getMonthDiff(params?.data?.aggrement?.eol_date);
                let ag_status = getAgreementStatus(params?.data?.aggrement, monthDiff).status || ''
                if (!statusList.includes(params?.data?.aggrement?.ag_status)) {
                  params.data.ag_status = ag_status;
                }
                // if (ag_status === params.data.ag_status) {
                //     params.data.ag_status = ag_status;
                // }
              }
            }
            if (params.data?.user_created) {
              let user = params.data.user_created
              params.data.user_created = user.first_name;
            }
            let rent_period = params?.data?.rent_period ? params?.data?.rent_period : 0
            let rent_month = Math.round(rent_period ? rent_period / parseInt(params?.data?.aggrement?.billing_period) : 0)
            params.data.rent_month = rent_month;
            return params.data[columnDef.field];
          }

        }

      };
      // if (columnDef.field === 'agrement_info_no') {
      //   let agremtList = agreementsInfoList && agreementsInfoList.sort((a, b) => (a > b ? -1 : 1));
      //   columnDefinition.cellEditor = 'DialogEditor';
      //   columnDefinition.cellEditorParams = {
      //     values: agremtList,
      //   };
      // }
      if (columnDef.type === 'date') {
        columnDefinition.cellEditor = 'dateEditor';
      }

      if (columnDef.field === 'product_group') {
        columnDefinition.cellEditor = 'AssetTypesEditor';
        columnDefinition.cellEditorParams = {
          values: assetTypes,
        };
      }
      if (columnDef.field === 'asset_status') {
        columnDefinition.cellEditor = 'agSelectCellEditor';
        columnDefinition.cellEditorParams = {
          values: statusNames,
        };
      }

      // if (columnDef.field === 'totalAssets') {
      //     columnDefinition.cellRenderer = params => {
      //         params.data.totalAssets = getAssetsCount(params.data.id)
      //         return params.data.totalAssets
      //     };

      // }
      return columnDefinition;
    });
  };

  function capitalizeFirstLetter(string) {
    return string.split(' ').map(w => w ? (w[0]?.toUpperCase() + w?.slice(1)) : '').join(' ');
  }

  const frameworkComponents = {
    ActionRendererComponent,
    DropDownSelect,
    TextFilterParams,
    EqualFilterParams
  };

  const onModelUpdated = (params) => {
    // console.log("onModelUpdated" ,params)
    if (!enableBulkUpdates) {
      gridApi && gridApi.deselectAll()
    }
    // params.api.sizeColumnsToFit();
  };

  const onRowSelected = (params) => {
    setEnableBulkUpdates(gridApi?.getSelectedRows()?.length > 0);
  };
  const onRowDataChanged = (params) => {
    highlightUnsavedRows(params);
  };

  const highlightUnsavedRows = (params) => {
    if (!params || rowDataAPI.length === 0) {
      return;
    }
    let missingRowNodes = params.api.rowModel.rowsToDisplay.filter((row) => {
      if (!row.data.asset_id) {
        return row;
      }
    });

    if (missingRowNodes.length > 0) {
      missingRowNodes.map((node) => {
        if (params.node !== node) {
          node.setSelected(true);
        }
      });
    }
  };

  const onRowEditingStarted = (params) => {
    if (enableBulkUpdates) {
      return
    }
    gridApi.refreshCells({
      rowNodes: [params.node],
      // columns: [params.columnApi.columnController.allDisplayedColumns[0]],
      force: true,
    });
  };
  const handleBulkUpdate = (params) => {
    listsAllAgreement();
    setBulkUpdateOpen(true);
  };
  const handleNotestOpen = (params) => {
    setShowNotesDialog(true);
    setSelectedAsset(params)
  };

  useEffect(() => {
    if (bulkupdatedData && bulkupdatedData.length > 0) {
      // console.log("row dattta****", rowData)
      setAssetsListData(assetsListData);
      setRowDataAPI(JSON.parse(JSON.stringify(assetsListData)));
      // setState({})
    }
  }, [bulkupdatedData]);

  const handleNotesCancel = async () => {
    setShowNotesDialog(false);
  };

  const exportExcelData = async (exportData = []) => {
    await generateExcel(gridApi, `Assets-${moment().format('YYYY-MM-DD')}.xlsx`, 'assets', tablecolumns, null, selectedColumns, exportData);
    setShowExport(false)
  }

  const getClientList = (partnerId = null, client = null) => {
    if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {
      // console.log("partnerId", partnerId)
      if (partnerId) {
        values.partner = partnerId;
        listsAllAgreement()
        return;
      }
    }
    let queryFields = 'client.name,client.id,client.clients_org_no,partner.partner_org_no,partner.partner_name,partner.id'
    let filter = ''
    if (partnerId) {
      values.partner = partnerId
      filter = `?filter[partner][_eq]=${partnerId}&fields=`
      listsAllAgreement(partnerId, '')

    }
    if (client) {
      values.client = client
      filter = `?filter[client][_eq]=${client}&fields=`
      // listsAllAgreement('', client)
    }
    setSelected([])

    ClientAddessList(queryFields, filter)
      .then(({ data: { data } }) => {
        getassetTypes()

        if (data && data.length > 0) {
          if (!partnerId) {
            // values.client = "";
            setValues({ ...values })

            let partners = data.map(
              (item) => item.partner && item.partner
            );
            partners = partners.filter(function (e) { return e != null; });
            partners = _.orderBy(partners, ['partner_name'], ['asc']);
            setPartnersValues(_.uniqBy(partners, 'id'))

          }
          if (partnerId || client) {
            // values.partner = "";
            let clients = data.map(
              (item) => item.client
            );
            clients = _.orderBy(clients, ['name'], ['asc']);
            // console.log("values",values)
            // values.client = '';
            setValues({ ...values })
            setClientsValues(_.uniqBy(clients, 'id'))
            // if (client) {
            //   listsAllAgreement(client)
            // }
          }
        }
      })
      .catch((err) => {
        throw err;
        // setIsloading(false)
        //   setStatus('The login detail is incorrect')
      })
  }

  const listsAllAgreement = async (partner = null, client = null) => {
    let fields = `id,agrement_info_no,heading,montly_payment_ratio,lease_start_date,billing_period`
    let options = {
      limit: -1,
      sort: '-id',
      fields: [fields]
    }
    if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {
      if (values.partner) {
        options.filter = {
          _and:
            [
              {
                "partner.id": {
                  _eq: values.partner
                }
              },
              {
                "client.id": {
                  _eq: userInfo.client.id
                }
              }
            ]
        }
      }

    } else {
      if (values.partner && client) {
        values.client = client
        options.filter = {
          _and:
            [
              {
                "partner.id": {
                  _eq: values.partner
                }
              },
              {
                "client.id": {
                  _eq: client
                }
              }
            ]
        }
      }
      if (values.partner && !client) {
        options.filter = {
          "partner.id": {
            _eq: values.partner
          }
        }
      }

      if (!values.partner && client) {
        values.client = client
        options.filter = {
          "client.id": {
            _eq: values.client
          }
        }
      }
    }
    const result = await directUSAPICall().items('aggrement').readMany(options);
    if (result.data && result.data.length > 0) {
      setAgreementList(result.data)
      setAgreementListTemp(result.data)
      // let agrement_info_no = result.data.map(
      //   (status) => status.agrement_info_no + '' + (status.heading ? ' - ' + status.heading : '')
      // );
      setAgreementsInfoList(result.data);

      // setLoading(false)
    } else {
      // setLoading(false)
      setAgreementList([])
      setAgreementsInfoList([])
    }

  }

  const submitFilter = (isStdView = false) => {
    let selectedColumns = []
    if (isStdView) {
      if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
        selectedColumns = tempColumns.filter((row) => row.stdview && row.field !== 'actions');
      } else if (userInfo?.userType == "PARTNER") {
        selectedColumns = tempColumns.filter((row) => row.partnerstdview && row.field !== 'actions');
      } else if (userInfo?.userType == "CLIENT") {
        selectedColumns = tempColumns.filter((row) => row.cientstdview && row.field !== 'actions');
      }
    } else {
      if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
        selectedColumns = tempColumns.filter((row) => row.checked);
      } else if (userInfo?.userType == "PARTNER") {
        selectedColumns = tempColumns.filter((row) => row.checked);
      } else if (userInfo?.userType == "CLIENT") {
        selectedColumns = tempColumns.filter((row) => row.checked);
      }
    }
    setLoading(true)

    // return
    selectedColumns = selectedColumns.map(
      (status) => status.field
    );
    selectedColumns = ['actions', ...selectedColumns]
    let obj = {
      "status": 'published',
      // "role": currentUser.role.id,
      "user": userInfo.id,
      "filter": null,
      "collection": "Asset",
      "layout_query1": {
        "tabular": {
          "page": 1,
          "fields": selectedColumns,
          "sort": [
            "-created_at"
          ],
          "limit": 300
        }
      },
    }
    if (selectedColumns?.length > 0) {
      let tempFields = selectedColumns;
      let val = tempColumnDefs.filter((row) => tempFields.indexOf('' + row.field) !== -1)
      tempColumnDefs.forEach((item) => {
        if (tempFields.some((x) => x == item.field)) {
          item.checked = true;
        } else {
          item.checked = item.field === 'actions' ? true : false;
        }
      })
      setTempColumns(tempColumnDefs)
      setColumnDefs(val);
    }
    if (presetId) {
      if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
        UpdatePresets(obj, presetId)
          .then(({ data: { data } }) => {
            setLoading(false)
            // lists(gridApi, null, selectedColumns)

            gridApi.hideOverlay();
          })
          .catch((err) => {
            setLoading(false)
            throw err;
          });
      } else {

        UpdateFilter(obj, presetId)
          .then(({ data: { data } }) => {
            setLoading(false)
            // lists(gridApi, null, selectedColumns)

            gridApi.hideOverlay();
          })
          .catch((err) => {
            setLoading(false)
            throw err;
          });
      }
    } else {
      if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
        CreatePresets(obj)
          .then((response) => {
            if (response?.data?.data) {
              setPresetId(response.data.data.id);
              lists(gridApi, null, selectedColumns, dataSourceparams)
            } else {
              lists(gridApi, null, selectedColumns, dataSourceparams)
            }
          })
          .catch((err) => {
            setLoading(false)
            throw err;
          });
      } else {
        CreateFilter(obj)
          .then((response) => {
            if (response?.data?.data) {
              setPresetId(response.data.data.id);
              lists(gridApi, null, selectedColumns, dataSourceparams)
            } else {
              lists(gridApi, null, selectedColumns, dataSourceparams)
            }
            // gridApi.hideOverlay();
          })
          .catch((err) => {
            lists(gridApi, null, selectedColumns, dataSourceparams)
            throw err;
          });
      }
    }

  }

  const getPresets = async (params, page) => {
    // if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
    //   callLists()
    //   return
    // }
    // if (Object.keys(params.filterModel).length > 0) {
    //               lists(gridApi, null, tempFieldsList, params, page)

    //   return
    // }
    let param = `?filter={"_and":[{"collection":{"_eq":"Asset"}},{"user":{"id":{"_eq":"${userInfo.id}"}}}]}`

    getFilters(param)
      .then(({ data: { data } }) => {

        if (data && data.length > 0) {
          setPresetId(data[0].id)
          if (data[0].layout_query1?.tabular?.fields?.length > 0) {
            let tempFields = data[0].layout_query1.tabular.fields;
            let val = tempColumnDefs.filter((row) => tempFields.indexOf('' + row.field) !== -1)
            tempColumnDefs.forEach((item) => {
              if (tempFields.some((x) => x == item.field)) {
                item.checked = true;
              } else {
                item.checked = item.field === 'actions' ? true : false;
              }
            })
            setTempColumns(tempColumnDefs)
            setColumnDefs(val);
            let cols = []
            val.map((item) => {
              let obj = {};
              if (item.field !== 'actions' && !item.hide) {
                obj[item.field] = ""
                obj = {
                  'key': item.field,
                  'value': "",
                  'placeholder': item.header_name,
                  'operator': item.operator,
                  'type': item.type
                }
                cols.push(obj)
              }
            })
            setColValues(cols)
            setTempFieldsList(tempFields)
            lists(gridApi, null, tempFields, params, page)
          } else {
            setLoading(true);
            callLists(params, page);
          }
        } else {
          setTempColumns(tempColumnDefs)
          callLists(params, page);

        }
      }).catch((err) => {
        listsAllAgreement([], tempFieldsList)
      })
  }

  const callLists = (request, page) => {
    let val = [];
    let tempCol = tempColumnDefs

    if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
      val = tempCol.filter((row) => row.adminview);
    } else if (userInfo?.userType == "PARTNER") {
      val = tempCol.filter((row) => row.partnerview);
    } else if (userInfo?.userType == "CLIENT") {
      val = tempCol.filter((row) => row.clientview);
    }
    let tempFields = val.map(
      (row) => row.field
    );
    tempCol.forEach((item) => {
      if (tempFields.some((x) => x == item.field)) {
        item.checked = true;
      } else {
        item.checked = item.field === 'actions' ? true : false;
      }
    })
    setTempColumns(tempCol)
    // let results = tempColumnDefs.filter(o1 => tempFields.some(o2 => o1.field === o2));
    // console.log("tempColumnDefs", val)
    setColumnDefs(val)
    let cols = []
    val.map((item) => {
      let obj = {};
      if (item.field !== 'actions' && !item.hide) {
        obj[item.field] = ""
        obj = {
          'key': item.field,
          'value': "",
          'placeholder': item.header_name,
          'operator': item.operator,
          'type': item.type
        }
        cols.push(obj)
      }
    })
    setColValues(cols);
    setTempFieldsList(tempFields)

    lists(gridApi, null, tempFields, request, page)
  }

  const handleAssetsList = (gridApi) => {
    lists(gridApi, null, tempFieldsList, dataSourceparams)
    setShowAssetCreate(false)
  }


  const onGridReady = (params) => {
    getColumnDefinitions();
    setGridApi(params.api);

  };


  useEffect(() => {
    if (userInfo && gridApi && tempColumnDefs && tempColumnDefs.length > 0) {
      const dataSource = {
        getRows: (params) => {
          const page = params.endRow / rowsPerPage;
          getPresets(params, page)
          setDataSourceparams(params);
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [userInfo && gridApi, tempColumnDefs]);

  const clearFilters = () => {
    gridApi.setFilterModel(null);
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 225,
      floatingFilter: true,
    };
  }, []);

  useEffect(() => {
    if (isSelectAll) {
      gridApi.selectAll();
      // gridApi.setServerSideSelectionState({
      //   selectAll: true
      // });
    }
  }, [isSelectAll])


  return (
    <>
      {/* <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} /> */}
      <div className="header-top">

        <div className="card-toolbar btns">

          {
            (page === '/assets' && !searchcolumns) && <FormControl className={classes.formControl1}>
              <MatSelect
                value={selectedAssetType}
                className={'matselect'}
                ref={externalFilterRef}
                onChange={(event) => {
                  // console.log("event", event)
                  localStorage.setItem('selectedAssetType', event.target.value)
                  externalFilterChanged(event.target.value);
                }}
              >
                <MenuItem value='All'>All</MenuItem>
                {assetTypes.map((type, index) => (
                  <MenuItem key={index} value={type}>{type}</MenuItem>
                ))}
              </MatSelect>
            </FormControl>
          }
          {/* {
                    !searchcolumns && page === '/assets' && (userPermission && userPermission && userPermission.canBulkUpdate) &&
                    <Avatar className={"avatar"}>

                      <Tooltip  title="Bulk Update">
                        <Link to='#' className='align-self-center' onClick={() => enableBulkUpdates && handleBulkUpdate(gridApi)} >
                          <EmailIcon />
                        </Link>
                      </Tooltip>

                    </Avatar>
                  } */}
          {/* <Button variant="contained" color="primary" onClick={() => enableBulkUpdates && handleBulkUpdate(gridApi)} className={classes.button}></Button> */}
          {
            <Link to='#' className='align-self-center' onClick={() => clearFilters(gridApi)} >
              <i className="fas fa-sync"></i> Clear filters
            </Link>
          }
          {
            userPermission.canBulkUpdate &&
            <Link to='#' className='align-self-center' onClick={() => enableBulkUpdates && handleBulkUpdate(gridApi)} >
              <i className="fas fa-list"></i> Bulk Update
            </Link>
          }
          {!searchcolumns && (userPermission && userPermission && userPermission.canBulkDelete) &&
            <Link to="#" className='align-self-center' onClick={() => enableBulkUpdates && handleBulkDelete(gridApi)} ><i className="fas fa-trash-alt"></i> {'Bulk delete'}</Link>
          }
          {

            ((userPermission && userPermission && userPermission.canImport) && !searchcolumns && page && (page === '/assets' || page === '/assetsnew' || page.includes('agrementassets') || page === '/agreements')) &&
            <Link to="#" className='align-self-center' onClick={() => setFileUploadOpen(true)} >
              <i className="fas fa-upload"></i> {'Import file'}
            </Link>
          }
          <Link className='align-self-center' to="#" onClick={() => (enableBulkUpdates) ? exportExcelData() : setShowExport(true)}><i className="fas fa-download"></i> Export file
        </Link>
          {
            userPermission.canImport && <Link className='align-self-center' onClick={() => handleCopy()} to="#">
              <i color="primary" className="fas fa-copy">
              </i> {'Copy Assets'}
            </Link>
          }
          {
            userPermission.canAdd && !searchcolumns && createPath && createTitle && <div className="add-agg"><Link to={`/${urlParams.id ? "createaggreementasset/" + urlParams.id : createPath}`} className='align-self-center'>
              <Button variant="contained" color="primary" className={classes.button}>
                <i className="fas fa-plus" style={{ color: 'white' }}></i> {createTitle}
              </Button>
              {/* <button type="button" className="btn btn-primary">Create Asset</button> */}
            </Link></div>
          }
        </div>
        {
          (!searchcolumns) &&
          Object.keys(urlParams).length === 0 && < div style={{ width: '100%', background: '#f8f8f8' }}>
            <div className="row filter">
              <div className='col-lg-2' style={{ display: 'none' }}>
                <div className="title-full title-full-1">
                  {/* <Link to={`/overview}`}>
                    <img className="left-icon" src={toAbsoluteUrl('/media/left_icon.png')} alt='' />
                  </Link> */}
                  <span>
                    {'Assets'}
                  </span>
                </div>

              </div>
              {
                (userInfo?.role?.name !== 'partner_admin' && userInfo?.role?.name !== 'partner_user') &&
                <div className='col-lg-3'>
                  <Autocomplete
                    id="size-small-standard11"
                    value={values.partner ? partnersValues.find(v => v.id === values.partner) || {} : null}
                    getOptionLabel={(option) => option.partner_name + " - " + option.partner_org_no}
                    options={partnersValues}
                    onChange={(e, option) => {
                      let vals = { ...values, partner: option ? option.id : null, client: null, aggrement: null }
                      setValues(vals)
                      if (option) {
                        getClientList(option.id)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // value={values.partner}
                        label="Partner"
                        margin="normal"
                        variant="standard"
                      />
                    )}
                  />
                </div>}
              {
                (userInfo?.role?.name !== 'client_admin' && userInfo?.role?.name !== 'client_user') &&

                <div className='col-lg-3'>
                  <Autocomplete
                    value={values.client ? clientsValues.find(v => v.id === values.client) || {} : null}
                    id="size-small-standard1"
                    getOptionLabel={(option) => option.name + " - " + option.clients_org_no}
                    options={clientsValues}
                    onChange={(e, option) => {
                      let vals = { ...values, client: option ? option.id : null, aggrement: null }
                      setValues(vals)
                      if (option) {
                        listsAllAgreement('', option.id)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // value={values.client}
                        label="Client"
                        margin="normal"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              }
              <div className='col-lg-3'>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id="multiple-limit-tags"
                  options={agreementList}
                  disableCloseOnSelect
                  defaultValue={selected?.length > 0 ? selected : []}
                  onChange={(e, item) => {
                    if (item) {
                      setSelected(item)
                    }
                  }}
                  getOptionLabel={(option) => option.agrement_info_no}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      {option.agrement_info_no}
                    </li>
                  )}
                  // style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField margin="normal" {...params} label="Agreements" placeholder="Agreements" />
                  )}
                />
              </div>
              <div className='col-lg-3 row'>
                <div className={classes.buttonContainer}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setTableColumns(tablecolumns.filter(x => !x.title.includes('Upcoming rents')));
                      setSelected([])
                      if (userInfo?.userType !== "PARTNER") {
                        values.partner = null;
                        values.aggrement = null
                        values.aggrement = ""
                      }
                      if (userInfo?.userType !== "CLIENT") {
                        values.client = null;
                      }
                      setSelected('')
                      values.aggrement = null
                      values.aggrement = ""
                      setValues({ ...values })
                      lists(gridApi, null, tempFieldsList, dataSourceparams)
                    }
                    }
                    color='secondary'
                    className={classes.button}
                  >
                    Reset</Button>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setTableColumns(tablecolumns.filter(x => !x.title.includes('Upcoming rents')));
                      localStorage.setItem("filterValues", JSON.stringify(values))
                      lists(gridApi, null, tempFieldsList, dataSourceparams)

                    }
                    }
                    color='primary'
                    className={classes.button}
                  >
                    Search
                </Button>
                </div>
              </div>
            </div>
          </div>
        }

        {/* {
          false &&
          <div>
            <FilterSelection
              userTypes={userTypes}
              userRole={loggedUserRole}
              agreementList={agreementList}
              clientsValues={clientsValues}
              partnersValues={partnersValues}
              selected={selected}
              title="Assets"
              setSelected={setSelected}
              setValues={setValues}
              values={values}
              getClientList={getClientList}
              getValues={getAgValuess}
              totalAssetsList={rowDataTemp}
            />
          </div>
        } */}
      </div>

      <div className="card card-custom gutter-b bg-smoke padd">
        {
          totalAssetValue && <div>
            <span>Total Asset value: </span><span>{SVformatter(totalAssetValue)} kr</span>
          </div>
        }
        {/* <div className='col-lg-6'>
          <Checkbox
            // defaultChecked={values.enable_EOL_choice}
            onChange={(event) => {
              setisSelectAll(event.target.checked);
            }}
          /> Select all
                </div> */}
        <div className='card-header border-0 card-header-sm'>
          {
            <Drawer
              columnDefs={tempColumns}
              setTempColumnDefs={setTempColumns}
              submitFilter={submitFilter}
              onClick={onClick}
              setIsSelect={setIsSelect}
              isSelect={isSelect}
            />
          }
          <div className={`card-title ${page}-title`}>
            <h3 className='card-label text-bold'>
              {'Assets List'} {searchString ? ' - ' + searchString : ''}
            </h3>
          </div>
        </div>

        <div
          className='ag-theme-quartz'
          style={{
            width: '100%',
            height: '82vh',
            boxShadow: '0 1px 15px 1px rgba(69,65,78,.08)',
          }}
        >
          <AgGridReact
            ref={gridRef}
            // columnDefs={[{'header_name': "FIRST NAME", 'field': 'first_name'}]}
            columnDefs={buildColumnDefinitions(columnDefs)}
            pagination={true}
            rowModelType={'infinite'}
            paginationPageSize={rowsPerPage}
            cacheBlockSize={rowsPerPage}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            onRowDataChanged={onRowDataChanged}
            onRowEditingStarted={onRowEditingStarted}
            enableCellTextSelection={true}
            overlayLoadingTemplate={overlayLoadingTemplate}
            overlayNoRowsTemplate={noRowsTemplate}
            onRowSelected={onRowSelected}
            gotoEdit={gotoEdit}
            enableBulkUpdates={enableBulkUpdates}
            searchString={searchString}
            deleteConfirm={deleteConfirm}
            handleNotestOpen={handleNotestOpen}
            applyColumnDefOrder={true}
            suppressRowClickSelection={true}
            alwaysShowVerticalScroll={true}
            quickFilterText={quickFilterText}
            userPermission={userPermission}
            onModelUpdated={onModelUpdated}
            components={frameworkComponents}
            getRowHeight={20}
            cacheOverflowSize={100}
            maxConcurrentDatasourceRequests={-1}
            infiniteInitialRowCount={1}
            maxBlocksInCache={rowsPerPage}
            rowSelection='multiple'
          />
          <CustomDropdown
            options={[25, 50, 100, 500,1000]}
            title={'Page Size'}
            value={rowsPerPage}
            onChange={(value) => {
              setRowsPerPage(value);
              gridApi.paginationSetPageSize(value);
            }}
          />
        </div>
        <DeleteConfirmDialog
          title="Asset"
          content="are you sure you want to delete this Asset"
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          tableName="Asset"
          deleteId={deleteId}
          tempFieldsList={tempFieldsList}
          getNewData={lists}
          parentGridApi={gridApi}
        />

        <NotesDialog
          handleCancel={handleNotesCancel}
          // userRole={userRole}
          selectedItem={selectedAsset}
          showDialog={showNotesDialog}
          collection="Asset"
          userPermission={userPermission}
        />
        <BulkUpdateDialog
          open={bulkUpdateOpen}
          title='bulk update'
          columnDefs={columnDefs}
          parentGridApi={gridApi}
          getNewData={lists}
          setOpen={setBulkUpdateOpen}
          assetTypes={assetTypes}
          values={values}
          agreementsInfoList={agreementsInfoList}
          statusNames={statusNames}
          setUpdatedData={setBulkupdatedData}
          tempFieldsList={tempFieldsList}
          lists={lists}
          PRODUCTCATEGORIES={PRODUCTCATEGORIES}
          dataSourceparams={dataSourceparams}

        // setGridApi={setGridApi}
        // gridApi={gridApi}
        />
        <FileUpload
          open={fileUploadOpen}
          setOpen={setFileUploadOpen}
          title='Asset File upload'
          handleCancel={setFileUploadOpen}
          getNewData={lists}
          parentGridApi={gridApi}
          tempFieldsList={tempFieldsList}
          dataSourceparams={dataSourceparams}
        />
        <AssetViewDialog
          open={showAssetDetail}
          setOpen={setShowAssetDetail}
          fieldDisable={true}
          setLoading={setLoading}
          page="view"
          title={`Asset View - ${selectedAssetId}`}
          assetId={selectedAssetId}
          setSelectedAsset={setSelectedAssetId}
          userPermission={userPermission}
        />
        <AssetViewDialog
          open={showAssetCreate}
          setOpen={setShowAssetCreate}
          fieldDisable={false}
          userPermission={userPermission}
          setLoading={setLoading}
          page="edit"
          title={`Asset modify ${!hideColumns ? " - " + selectedAssetId : ''}`}
          assetId={selectedAssetId}
          setSelectedAsset={setSelectedAssetId}
          setShowAssetCreate={setShowAssetCreate}
          handleAssetsList={handleAssetsList}
        />
        <ExportModal
          open={showExport}
          setOpen={setShowExport}
          lists={lists}
          parentGridApi={gridApi}
          // filterValues={filterValues}
          totalRows={totalRowDatasFilterCount}
          isDownloaded={isDownloaded}
          tempFieldsList={tempFieldsList}
          dataSourceparams={dataSourceparams}
        />
      </div>

      {/* <Sidebar
          handleFilter={submitFilter} columns={temptablecolumns} setTempTableColumns={setTempTableColumns} onClick={onClick} onChange={handleChange} selectedColumns={selectedColumns} />
 */}
      {/* </div> */}
    </>
  )
}


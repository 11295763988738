// import { makeStyles, createStyles } from '@material-ui/core/styles';
// import {
//   EyeFilled,
//   PlusOutlined,
//   DeleteFilled,
//   SearchOutlined,
//   OrderedListOutlined,
//   EditFilled,
//   DownOutlined,
//   CheckCircleFilled,
//   CloseCircleFilled
// } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
// import styles from '../style.less';
// import { message, Col, Modal, Upload, Button, Icon, Spin } from 'antd';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const ActionCellRenderer = (props) => {
  // console.log('action cell ', props, props.api.getEditingCells());
  // const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const { data } = useSelector(({ auth }) => auth.user);
  const [userPermission, setUserPermission] = useState();
  const [userInfo, setUserInfo] = useState(data);
  const [loggedUserRole, setLoggedUserRole] = useState();

  useEffect(() => {
    setEditMode(
      props.api.getEditingCells().length > 0
        ? props.api.getEditingCells()[0].rowIndex === props.node.rowIndex
        : false
    );
  }, [props]);

  const handleEdit = () => {
    console.log(" props.node", props.node.data.id)
    setEditMode(true);
    // props.api.startEditingCell({
    //   rowIndex: props.node.rowIndex,
    //   colKey: props.colDef.cellRendererParams.colKey
    //     ? props.colDef.cellRendererParams.colKey
    //     : 'quantity',
    // });
  };

  const handleDone = () => {
    setEditMode(false);
    props.api.stopEditing();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userInfo && userInfo.id && userInfo?.role?.name) {
      setLoggedUserRole(userInfo.role.name)
      setUserInfo(userInfo)
    }
  }, [userInfo])

  useEffect(() => {
    if (props && props.agGridReact.props.userPermission) {
      setUserPermission(props.agGridReact.props.userPermission)
    }
  }, [props])

  const deleteConfirm = () => {
    props.agGridReact.props.deleteConfirm(props.node);
  };

  const handleView = () => {
    props.agGridReact.props.gotoEdit(props, 'view');
  };

  const handleNotestOpen = () => {
    props.agGridReact.props.handleNotestOpen(props);
  };

  return (
    <div>
      {/* <i className="fas fa-edit" /> */}
      {
        (userPermission && userPermission.canView) && <i onClick={() => handleView()} className="fa fa-eye" />
      }
      {(userPermission && userPermission.canEdit) && (
        <Link className="viewbtn" to={`${"/updateagreement/" + props.node.data.id}`} ><i className="fa fa-edit" /></Link>
      )}
      {(userPermission && userPermission.canNotes) && <i className={`${props.node.data.notes? 'fas' : 'far'} fa-sticky-note`} onClick={() => handleNotestOpen(props.node)}></i>}
      {(userPermission && userPermission.canDelete) && <i className="fa fa-trash-alt deleteone" onClick={() => deleteConfirm()} />}
    </div>
  );
};

export default ActionCellRenderer;

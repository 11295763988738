import UploadFileIcon from '@mui/icons-material/UploadFile';
import Check from '@material-ui/icons/Check';
import Cancel from '@material-ui/icons/Cancel';

// import { Steps, Col, Progress, Icon, Spin } from 'antd';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import XLSX from 'xlsx';

import {
  FILE_UPLOAD_EMPTY_FILE_MESSAGE,
  AGGREMENTFIELDSFILTER
} from '../../../../_metronic/helpers/components/constant'
// import AppContext from '../context/AppContext';
// import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { directUSAPICall, groupByAgreement } from '../../../../_metronic/helpers/components/utils';
import React, { useState, useRef, useEffect } from 'react';
import _ from "lodash";
import { List } from '../../agreements/redux/AgreementCrud';
import CircularProgressWithLabel from "../../../../_metronic/helpers/progressBar";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogRoot: {
      width: '600px',
      height: '450px',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '40px',
      background: 'linear-gradient(to right, #eef2f3, #8e9eab)',
      boxShadow: '1px 1px 3px #8e9eab',
    },
    dialogTitleText: {
      display: 'flex',
      justifyContent: 'space-between',
      // fontFamily: "'Poppins'",
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: '0.85rem',
    },
    dialogContent: {
      //   marginTop: '10px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '50%',
      marginBottom: '15px',
      marginRight: '15px',
    },
    button: {
      marginLeft: '15px',
    },
    buttonProgress: { marginLeft: '5px' },
    actionIcon: {
      fontSize: '1.25rem',
      cursor: 'pointer',
    },
    stepContent: {
      width: '100%',
    },
    steps: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      //   paddingLeft: '20px',
    },
    stepsText: {
      marginTop: '15px',
      // fontFamily: 'Poppins',
      fontSize: '20px',
    },
    stepsTextError: {
      marginTop: '15px',
      // fontFamily: 'Poppins',
      fontSize: '20px',
      color: 'red',
      textAlign: 'center',
    },
    stepsSubText: {
      marginTop: '5px',
      // fontFamily: 'Poppins',
      fontSize: '12px',
    },
    uploadArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#ababab',
      '&:hover': {
        color: '#003366',
        cursor: 'pointer',
      },
    },
    uploadIcon: {
      fontSize: '80px',
      // color: '#cdcdcd',
      // '&:hover': {
      //   color: '#003366',
      //   cursor: 'pointer',
      // },
    },
    successIcon: {
      color: '#003366',
      marginBottom: '15px',
    },
    errorIcon: {
      color: '#eb8034',
      marginBottom: '15px',
    },
    successArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    spinnerArea: {
      marginBottom: '10px',
    },
    animatedItem: {
      animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    recordsCount: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
      fontSize: '14px',
      marginBottom: '10px'
    }
  })
);

const FileUploadDialog = ({
  open,
  title,
  setOpen,
  getNewData,
  tempFieldsList,
  parentGridApi,
  dataSourceparams
}) => {
  // console.log('Allassets', allAssets, assetTypes, statusNames);
  const classes = useStyles();
  const fileUploader = useRef(null);
  // const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState('');
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [updateRecordsCount, setUpdateRecordsCount] = useState(0);
  const [steps, setSteps] = useState([
    'Select a file',
    'Update Records',
    'Insert Records',
  ]);
  const [agreementList, setAgreementList] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [assetIDRows, setAssetIDRows] = useState([]);
  const [nonAssetIDRows, setNonAssetIDRows] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  const [importId, setImportId] = useState('');
  
  useEffect(() => {
    handleReset();
    if (open) {
      setImportId(Math.floor(Math.random() * 90000) + 10000)
      listsAllAsset()
      listsAllAgreement()
    }
  }, [open]);

  const listsAllAsset = async () => {
    const result = await directUSAPICall().items('Asset').readMany({ limit: -1, fields: ['id, serial_number,aggrement'] });
    if (result.data && result.data.length > 0) {
      setAllAssets(result.data);
    }
  }


  const listsAllAgreement = () => {
    // setLoading(true)
    let fields = `?limit=-1&fields=${AGGREMENTFIELDSFILTER}`
    List(fields)
      .then(({ data: { data } }) => {
        if (data && data.length > 0) {
          setAgreementList(data)
        } else {
          setAgreementList([])
        }
      })
      .catch(() => {
        setAgreementList([])
        //   setStatus('The login detail is incorrect')
      })
  }

  useEffect(() => {
    if (!success) {
      return;
    }
    // console.log("allAssets", allAssets)
    let allAssetIDs = allAssets.map((asset) => asset.id);
    // console.log('allAssetIDs', allAssetIDs);

    setAssetIDRows(
      data.filter((row) => allAssetIDs.indexOf(row.id) !== -1)
    );
    setNonAssetIDRows(
      data.filter((row) => allAssetIDs.indexOf(row.id) === -1)
    );

  }, [success, allAssets]);
  // console.log('allAssets', allAssets);
  // console.log('setNonAssetIDRows', nonAssetIDRows);

  const handleReset = () => {
    setLoading(false);
    setSuccess(false);
    setError(false);
    setData([]);
    setCols([]);
    setCurrentStep(0);
    setAssetIDRows([]);
    setNonAssetIDRows([]);
    setFileUploadSuccess(false);
    setFileUploadErrorMessage('');
    setFileUploadError(false);
    if (fileUploader.current) {
      fileUploader.current.value = null;
    }
  };

  const handleNext = () => {
    setSuccess(false);
    setError(false);
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleUpdate = async () => {
    setLoading(true);
    let summaryOffset = 0;

    try {
      let result;
      for (let i = 0; i <= assetIDRows.length; i++) {
        if (assetIDRows[i] && assetIDRows[i].id) {
          setUpdateRecordsCount(i + 1);
          // console.log("assetIDRows", assetIDRows)
          if (updateRecordsCount === assetIDRows.length) {
            break
          }
          result = await directUSAPICall().items('Asset').updateOne([assetIDRows[i].id], assetIDRows[i]);

        }
      }
      setLoading(false);
      setUpdateRecordsCount(0)
      let allAgrementDs = assetIDRows.map((asset) => asset.aggrement);
      groupByAgreement(allAgrementDs)

      if (!updateRecordsCount) {
        // getNewData(parentGridApi);

        setAssetIDRows([])
        setSuccess(true);
        // setUpdateRecordsCount(0)

      } else {
        summaryOffset = 0;
        setUpdateRecordsCount(0)

        setError(true);
        setLoading(false);
        setFileUploadErrorMessage('Failed to imported');
      }

      // if (response) {
      //   setSuccess(true);
      // } else {
      //   summaryOffset = 0;
      //   setError(true);
      //   setLoading(false);
      //   setFileUploadErrorMessage('Agreement info imported successfully ');
      // }
    } catch (err) {
      console.log('update error', err);
      setLoading(false);
      setError(false);
      setFileUploadErrorMessage('Failed to imported');
      throw err;
    }
  };

  const handleInsert = async () => {
    setLoading(true);
    let dataWithoutAssetIds = nonAssetIDRows.map((asset) => {
      let tempObject = { ...asset };
      if (!tempObject.id) {
        tempObject.id = null;
      }
      Object.keys(tempObject).forEach((key) => {
        if (!tempObject[key]) {
          delete tempObject[key];
        }
      });

      return tempObject;
    });

    let summaryOffset = 0;

    try {
      let noofRequests = 50;
      // let summaryLoopCount = parseInt(dataWithoutAssetIds.length)
      // console.log("summaryLoopCount", summaryLoopCount)
      // console.log( "tempassetIDRows", tempassetIDRows)
      let tempassetIDRows = dataWithoutAssetIds;
      let insertSuccess;
      for (let i = 0; i <= dataWithoutAssetIds.length; i += 50) {
        let filterassetIDRows = tempassetIDRows.slice(i, noofRequests + i);
        insertSuccess = await directUSAPICall().items('Asset').createMany(filterassetIDRows);
        summaryOffset += filterassetIDRows.length;
        setUpdateRecordsCount(summaryOffset);
      }

      setLoading(false);
      setUpdateRecordsCount(0)
      let allAgrementDs = dataWithoutAssetIds.map((asset) => asset.aggrement);
      await groupByAgreement(allAgrementDs)
      if (insertSuccess?.data?.length > 0) {
        setSuccess(true);
      } else {
        setError(true);
        setLoading(false);
        setFileUploadErrorMessage('Asset Successfully imported');
      }
    } catch (err) {
      setLoading(false);
      setError(false);
      setFileUploadErrorMessage('Failed to import. Please try again.');
      throw err;
    }
  };

  // function ExcelDateToJSDate(serialDate) {
  //   var days = Math.floor(serialDate);
  //   var hours = Math.floor((serialDate % 1) * 24);
  //   var minutes = Math.floor((((serialDate % 1) * 24) - hours) * 60)
  //   return new Date(Date.UTC(0, 0, serialDate, hours - 17, minutes));
  // }

  const getAgreemntOrgNo = (item, column) => {
    let response = agreementList.filter((row) => row[column] == item)
    return response && response.length > 0 ? response[0] : ""
  }

  const getAssetAgreemntOrgNo = (id) => {
    let response = allAssets.filter((row) => row.id == id)
    return response && response.length > 0 ? response[0] : ""
  }


  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    setLoading(true);
    try {
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        // const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const wb = XLSX.read(bstr, { type: 'binary' });
        // const wb = XLSX.readFile(file.path);
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        // const data = XLSX.utils.sheet_to_json(ws, { defval: null });
        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(ws, {
          defval: null,
          // header:1,
          blankRows: false
        });
        let newAgreements = [];

        data.forEach((item) => {
          let agRefId = ''
          let selectedAggrement = {}
          let assetInfo = {}
          if (item.id) {
            item.id = item.id;
            assetInfo = getAssetAgreemntOrgNo(item.id);
            // item.aggrement = assetInfo.aggrement

            selectedAggrement = getAgreemntOrgNo(assetInfo.aggrement, 'id');
            // console.log(assetInfo, "selectedAggrement **", selectedAggrement)

          }
          if (item.aggrement) {
            agRefId = item.aggrement;
            selectedAggrement = getAgreemntOrgNo(agRefId, 'agrement_info_no');
          } else if (item.agrement_info_no) {
            agRefId = item.agrement_info_no;
            selectedAggrement = getAgreemntOrgNo(agRefId, 'agrement_info_no');
          }
          //  else if (item.agreement) {
          //   agRefId = item.agreement;
          //   selectedAggrement = getAgreemntOrgNo(agRefId, 'agrement_info_no');
          // }
          // : ;z

          if (selectedAggrement == 0) {
            newAgreements.push(item.aggrement)
          }
          if (item.company_org_no) {
            delete item.company_org_no;
          }
          if (item.partner_org_no) {
            delete item.partner_org_no;
          }
          if (item.partner_name) {
            delete item.partner_name;
          }
          if (item.client_name) {
            delete item.client_name;
          }
          if (item.clients_org_no) {
            delete item.clients_org_no;
          }

          item.import_id = importId;
          if (selectedAggrement) {
            // console.log("selectedAggrement.partner", selectedAggrement)
            item.aggrement = selectedAggrement.id;
            // if (item.date_of_delievery_approval) {
            //   let date_of_delievery_approval = moment(ExcelDateToJSDate(item.date_of_delievery_approval)).format('YYYY-MM-DD')
            //   item.date_of_delievery_approval = date_of_delievery_approval;
            // } else {
            //   item.date_of_delievery_approval = '';
            // }
            if (selectedAggrement.partner) {
              item.partner = selectedAggrement.partner.id || '';
              item.partner_name = selectedAggrement.partner.partner_name || '';
            }
            if (selectedAggrement.client) {
              item.client = selectedAggrement.client.id || '';
              item.client_name = selectedAggrement.client.name || '';
            }
          } else {
            delete item.aggrement;
          }
          delete item.user_created;
          delete item.user_updated;
          delete item.date_created;
          delete item.date_updated;
        });
        //filter null values
        // console.log("dataaaa", data)

        data = data.filter((item) => item.aggrement || item.id);
        data = _.map(data, function (user) {
          return _.omit(user, function (value, key) {
            // console.log("*********", key)
            return key.charAt(0) == "_";
          });
        });
        /* Update state */
        setData(data);

        setCols(make_cols(ws['!ref']));
        // console.log('resetting load');

        setLoading(false);
        // checking all file upload errors here
        if (data.length === 0 || !data) {
          console.log('columns empty file', data);
          setSuccess(true);
          setFileUploadError(true);
          setFileUploadErrorMessage(FILE_UPLOAD_EMPTY_FILE_MESSAGE);
          return;
        }
        setFileUploadSuccess(true);
        setSuccess(true);
        // this.setState({ data: data, cols: make_cols(ws['!ref']) });
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    } catch (err) {
      setError(true);
      throw err;
    }

    // fileUploader.current.value = '';
  };

  const make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };

  const stepContent = (stepNumber) => {
    switch (stepNumber) {
      case 0: {
        return (
          <div className={classes.steps}>
            <input
              type='file'
              ref={fileUploader}
              style={{ display: 'none' }}
              accept={'.csv, .xls, .xlsx'}
              onChange={(e) => handleFileUpload(e)}
            />
            {!loading && !success && (
              <div
                onClick={(e) => fileUploader.current.click()}
                className={classes.uploadArea}
              >
                <UploadFileIcon
                  className={'uploadIcon'}
                />
                <div className={classes.stepsText}>Select a file</div>
                <div className={classes.stepsSubText}>
                  (.csv, .xls, .xlsx.)
                </div>
              </div>
            )}
            {loading && <CircularProgress color='green' size={50} />}

            {!loading && success && (
              <div className={classes.successArea}>
                {!fileUploadError && (
                  <Check
                    // icon={CheckCircleFilled}
                    className={clsx(
                      classes.uploadArea,
                      classes.uploadIcon,
                      classes.successIcon,
                      classes.animatedItem
                    )}
                  />
                )}
                {fileUploadError && (
                  <div className={classes.uploadArea}>
                    <Cancel
                      // icon={ExclamationCircleOutlined}
                      className={clsx(
                        classes.uploadIcon,
                        classes.errorIcon,
                        classes.animatedItem
                      )}
                    />
                    <div
                      className={classes.uploadArea}
                      onClick={(e) => {
                        fileUploader.current.click();
                        setTimeout(() => {
                          handleReset();
                        }, 500);
                      }}
                    >
                      Click here to try again
                    </div>
                  </div>
                )}
                {!fileUploadError && (
                  <div className={classes.stepsText}>
                    {data.length} rows found. Let's start reviewing
                  </div>
                )}
                {fileUploadError && (
                  <div className={classes.stepsTextError}>
                    {fileUploadErrorMessage}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      }
      case 1: {
        return (
          <div className={classes.successArea}>
            {!loading && !success && (
              <div>
                <div className={classes.stepsText}>
                  {assetIDRows.length} records with a matching Asset ID
                </div>
              </div>
            )}
            {loading && updateRecordsCount > 0 && <div className={classes.recordsCount}>{updateRecordsCount} of {assetIDRows.length} records completed.</div>}
            {/* {loading && <CircularProgress color='green' size={50} />} */}
            {loading && <CircularProgressWithLabel className={"progressbar"} value={Math.round((updateRecordsCount * 100) / assetIDRows.length)} />}
            {!loading && success && (
              <div>
                <div className={classes.stepsText}>Update Successful</div>
              </div>


            )}
            {!loading && error && (
              <div>
                <div className={classes.stepsTextError}>
                  {fileUploadErrorMessage}
                </div>
              </div>
            )}
          </div>
        );
      }
      case 2: {
        return (
          <div className={classes.successArea}>
            {!loading && !success && (
              <div>
                <div className={classes.stepsText}>
                  {nonAssetIDRows.length} new records found
                </div>
              </div>
            )}
            {loading && <CircularProgress color='green' size={50} />}
            {!loading && success && (
              <div>
                <div className={classes.stepsText}>Insertion Successful</div>
              </div>
            )}
            {!loading && error && (
              <div>
                <div className={classes.stepsTextError}>
                  {fileUploadErrorMessage}
                </div>
              </div>
            )}
          </div>
        );
      }
      default: {
        <div>Successful</div>;
      }
    }
  };
  // console.log("assetIDRows", assetIDRows)
  return (
    <Dialog
      open={open}
      //   onClose={getNewData(p)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='md'
    //   className={classes.dialogRoot}
    >
      <div className={classes.dialogRoot}>
        <DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
          <div className={classes.dialogTitleText}>
            <div>{title} - Import Id {importId}</div>
          </div>
          {/* <i className="fas fa-times" {...props} ref={ref} /> */}
          <Cancel
            className={classes.actionIcon}
            onClick={() => {
              setImportId('')
              setOpen(false)
            }}
          />
        </DialogTitle>

        <DialogContent className={[classes.dialogContent, "fileupload"].join(" ")}>
          <DialogContentText id='alert-dialog-description'>
            <Stepper
              activeStep={currentStep}
              alternativeLabel
            // style={{ width: '700px', height: '400px' }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </DialogContentText>
          <div className={classes.stepContent}>{stepContent(currentStep)}</div>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              onClick={() => handlePrevious()}
              color='secondary'
              disabled={currentStep === 0}
              className={classes.button}
            >
              Back
            </Button>

            {currentStep !== 2 && (
              <Button
                variant='contained'
                onClick={() => handleNext()}
                color='primary'
                disabled={
                  !assetIDRows.length === 0 ||
                  !fileUploadSuccess ||
                  (assetIDRows.length > 0 &&
                    (loading || fileUploadError || !success))
                }
                className={classes.button}
              >
                Next
                {/* {loading && (
                <CircularProgress
                  size='1rem'
                  className={classes.buttonProgress}
                /> 
              )}*/}
              </Button>
            )}

            {currentStep === 1 && !success && assetIDRows.length > 0 && (
              <Button
                variant='contained'
                onClick={() => handleUpdate()}
                color='primary'
                disabled={loading}
                className={classes.button}
              >
                Update
                {loading && (
                  <CircularProgress
                    size='1rem'
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            )}
            {currentStep === 2 && !success && nonAssetIDRows.length > 0 && (
              <Button
                variant='contained'
                onClick={() => handleInsert()}
                color='primary'
                disabled={loading}
                className={classes.button}
              >
                Insert
                {loading && (
                  <CircularProgress
                    size='1rem'
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            )}
            {currentStep === 2 &&
              (nonAssetIDRows.length === 0 ||
                (nonAssetIDRows.length > 0 && success)) && (
                <Button
                  variant='contained'
                  onClick={() => {
                    setImportId('')
                    getNewData(parentGridApi, null, tempFieldsList, dataSourceparams, null, null);
                    setOpen(false);
                  }}
                  color='primary'
                  disabled={loading}
                  className={classes.button}
                >
                  Finish
                  {loading && (
                    <CircularProgress
                      size='1rem'
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default FileUploadDialog;
